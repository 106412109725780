import React from 'react';
import { Container, Image, Card, Row, Col } from 'react-bootstrap';
import whatsappImg from './../../img/whatsapp.png';

function RedesSociales() {
    const phone = "5493816790209";
    const msg = "Escriba%20su%20mensaje...";
    const whatsAppLink = `https://api.whatsapp.com/send?phone=${phone}&text=${msg}`;
    return (
        <Container className=" text-center pt-3 pb-4" >
            <Row>
                <Col md={6}>
                    <Card  className="bg-transparent border-0">
                        <Card.Title className="font-weight-bolder text-center">
                        <Card.Text>Encontranos en:</Card.Text>
                            <a href="https://www.google.com/maps/place/Laboratorio+Calcagno/@-26.7935275,-65.2144975,17z/data=!4m5!3m4!1s0x94225dc85a90167d:0x25ce5e2d5eee950!8m2!3d-26.7919364!4d-65.2121121?hl=es">
                            <Image className="bordermap rounded-circle" src="https://i.ibb.co/2PRKLw0/ubicacion.png" width="50%" height="50%" alt="Ubicacion"/>
                            </a>
                        </Card.Title>
                    </Card>
                </Col>
                <Col md={5}>
                    <Card className="bg-transparent border-0 mt-2 mt-md-0 pt-0 ">
                            <Card.Title className="font-weight-bolder text-center ">
                                <Card.Text>Seguinos en Nuestras Redes Sociales</Card.Text>
                            <div className="mt-5 pt-0 pt-lg-5 ">
                                <a className="bg-transparent border-0 mr-4" href="https://www.facebook.com/laboratoriocalcagno" target="_blank" rel="noopener noreferrer">
                                    <Image src="https://i.ya-webdesign.com/images/logo-de-facebook-png.png" width="50" height="50" />
                                </a>
                                <a className="bg-transparent border-0 mt-5 mr-4" href="https://www.instagram.com/laboratoriocalcagno/" target="_blank" rel="noopener noreferrer">
                                    <Image src="https://i.ibb.co/52VmgmJ/instagram.png" width="50" height="50" />
                                </a>
                                <a className="bg-transparent border-0 mt-5 " href={whatsAppLink} target="_blank" rel="noopener noreferrer">
                                    <Image src={whatsappImg} width="50" height="50" />
                                </a>
                            </div>
                            </Card.Title>
                        
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default RedesSociales;