import React, { useEffect } from 'react';
import { Container, Card, Button, CardDeck, Row, Col, Image } from 'react-bootstrap';
import FarmaciaSanMiguel from './FarmaciaSanMiguel.png';
import FarmaciaYerbaBuena from './FarmaciaYerbaBuena.png';
import FarmaciaLomas from './FarmaciaLomas.png';
import banlab from '../Imagen/banlab.png'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { AnimatePresence } from 'framer-motion';
import { Switch, Route, Link, useLocation } from "react-router-dom";
import Smt from '../Locations/Smt';
import Yb from '../Locations/Yb';
import Tf from '../Locations/Tf';
import Brs from '../Locations/Brs';
import './WhereToBuy.css';

function WhereToBuy() {
    
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className="back">
            
            <Container className="py-5 " >

                <Card className="text-center mt-1 wheretobuyplace">
                    <h4 className="py-2 pb-4 border-success border-bottom">¿ Dónde comprar ?</h4>
                    <Card.Body >
                        <Row>
                            <Col xs={12} lg={3} className="py-2 border-success border-right">
                                <Link to="/dondecomprar/smt" className="px-2  px-lg-4 mx-5 mx-lg-0 border-0 zoombottoms font-weight-bold btn btn-secondary"> San Miguel de Tucumán</Link>
                            </Col>
                            <Col xs={12} lg={3} className="py-2 border-success border-right">
                                <Link variant="secondary" to="/dondecomprar/yb" className="px-2 px-lg-5 mx-5  mx-lg-0  border-0 zoombottoms font-weight-bold  btn btn-secondary" >Yerba Buena</Link>
                            </Col>
                            <Col  xs={12} lg={3} className="py-2 border-success border-right">
                                <Link variant="secondary" to="/dondecomprar/brs" className="px-2 px-lg-4 mx-5  mx-lg-0  border-0 zoombottoms font-weight-bold  btn btn-secondary" >Banda del Río Salí</Link>
                            </Col>

                            <Col xs={12} lg={3} className="py-2 border-success">
                                <Link variant="secondary" to="/dondecomprar/tf" className="px-2 px-lg-5 mx-5  mx-lg-0  border-0 zoombottoms font-weight-bold  btn btn-secondary" > Tafí Viejo</Link>
                            </Col>

                        </Row>
                    </Card.Body>
                </Card>
                <AnimatePresence exitBeforeEnter>
                    <Switch location={location} key={location.pathname}>
                        <Route path='/dondecomprar/yb' component={Yb}>
                    
                        </Route>
                        <Route path='/dondecomprar/tf' component={Tf}>

                        </Route>
                        <Route path='/dondecomprar/brs' component={Brs}>

                        </Route>
                        <Route path='/dondecomprar/smt' component={Smt}>

                        </Route>
                    </Switch>
                </AnimatePresence>


                {/*  cards */}

                <CardDeck className="mt-5 text-center">
                    <Card className="paper">
                        <Card.Img variant="top" className="border-bottom border-success" src={FarmaciaSanMiguel} />
                        <Card.Body>
                            <Card.Title>Farmacia Calcagno</Card.Title>
                            <Card.Title>San Miguel de Tucumán </Card.Title>
                            <Card.Text><FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />Av. Francisco de Aguirre 1498, T4001FVB San Miguel de Tucumán, Tucumán <br />
                                <FontAwesomeIcon icon={faPhoneAlt} className="mr-2" />Tel: 381 427-6689</Card.Text>
                        </Card.Body>
                        <Card.Footer className="border-top border-success">
                            <Button variant="secondary" href="https://www.google.com/maps/place/Farmacia+Calcagno/@-26.7919746,-65.2142076,17z/data=!3m1!4b1!4m5!3m4!1s0x94225dc857458053:0xdd6c840c2169137f!8m2!3d-26.7919794!4d-65.2120189" target="_blank" className="px-5 px-lg-5  border-0 zoombottoms font-weight-bold">Ver Ubicación</Button>
                        </Card.Footer>
                    </Card>
                    <Card className="paper">
                        <Card.Img variant="top" className="border-bottom border-success" src={FarmaciaYerbaBuena} />
                        <Card.Body>
                            <Card.Title>Farmacia Calcagno</Card.Title>
                            <Card.Title>Yerba Buena</Card.Title>
                            <Card.Text> <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />Gral. Lamadrid 1100, T4107 AJX, Tucumán <br /> <FontAwesomeIcon icon={faPhoneAlt} className="mr-2" />381 425-8836 </Card.Text>
                        </Card.Body>
                        <Card.Footer className="border-top border-success">
                            <Button variant="secondary" href="https://www.google.com/maps/place/Farmacia+Calcagno/@-26.8214357,-65.2881003,17z/data=!4m5!3m4!1s0x9422432cb90691e3:0xb6806fb888d7b159!8m2!3d-26.8214405!4d-65.2859116" target="_blank" className="px-5 px-lg-5  border-0 zoombottoms font-weight-bold">Ver Ubicación</Button>
                        </Card.Footer>
                    </Card>
                    <Card className="paper">
                        <Card.Img variant="top" className="border-bottom border-success" src={FarmaciaLomas} />
                        <Card.Body>
                            <Card.Title>Farmacia Calcagno</Card.Title>
                            <Card.Title>San Miguel de Tucumán </Card.Title>
                            <Card.Text><FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />Av. Francisco Jaldo 300, San Miguel de Tucumán, Tucumán <br /> <FontAwesomeIcon icon={faPhoneAlt} className="mr-2" /> Tel: 381 320-8238 </Card.Text>
                        </Card.Body>
                        <Card.Footer className="border-top border-success">
                            <Button variant="secondary" href="https://www.google.com/maps/place/Farmacia+Calcagno+Lomas/@-26.7804936,-65.233521,17z/data=!3m1!4b1!4m5!3m4!1s0x94225d98c4e12f95:0x50c6ff54c5a753a4!8m2!3d-26.7804984!4d-65.2313323" target="_blank" className="px-5 px-lg-5 border-0 zoombottoms font-weight-bold">Ver Ubicación</Button>
                        </Card.Footer>
                    </Card>
                </CardDeck>
            </Container>
            <Image src={banlab} fluid  alt=""/>
        </div>
    )
}

export default WhereToBuy;