import React, { useEffect }  from 'react';
import Slider from '../Slider/Slider';
import Cards from '../Cards/Cards';
import banlab from '../Imagen/banlab.png'
import { Image } from 'react-bootstrap';

function Home() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
            <div className="back">
                <Slider />
                <Cards />
                <Image src={banlab} fluid  alt=""/>
            </div>

    );
}

export default Home;