import React from 'react';
import './Slider.css';
import { Carousel, Image } from 'react-bootstrap';

function Slider() {
    return (
        <Carousel className=" mx-0 mx-md-5 px-0 px-md-5">
            <Carousel.Item className="px-0 px-md-5">
                <Image
                    thumbnail
                    className="d-block w-100 my-4 shadow-lg  mb-5 bg-white rounded"
                    src="https://i.ibb.co/Tb4LgDd/Moringa.jpg"
                    alt="First slide"
                />
            </Carousel.Item>
            <Carousel.Item className="px-0 px-md-5">
                <Image
                    thumbnail
                    className="d-block w-100 my-4 shadow-lg  mb-5 bg-white rounded"
                    src="https://i.ibb.co/DQX4pq1/resveratrol.jpg"
                    alt="six slide"
                />
            </Carousel.Item>
            <Carousel.Item className="px-0 px-md-5">
                <Image
                    thumbnail
                    className="d-block w-100 my-4 shadow-lg  mb-5 bg-white rounded"
                    src="https://i.ibb.co/bB877D1/rompe-Piedras.jpg"
                    alt="Second slide"
                />
            </Carousel.Item>
            <Carousel.Item className="px-0 px-md-5">
                <Image
                    thumbnail
                    className="d-block w-100  my-4 shadow-lg  mb-5 bg-white rounded"
                    src="https://i.ibb.co/XpZ4VjM/coenzima-Q10.jpg"
                    alt="Third slide"
                />
            </Carousel.Item>
            <Carousel.Item className="px-0 px-md-5">
                <Image thumbnail
                    className="d-block w-100 my-4 shadow-lg  mb-5 bg-white rounded"
                    src="https://i.ibb.co/r5SgCRk/acai-Berry.jpg"
                    alt="four slide"
                />
            </Carousel.Item>
            <Carousel.Item className="px-0 px-md-5">
                <Image thumbnail
                    className="d-block w-100 my-4 shadow-lg  mb-5 bg-white rounded"
                    src="https://i.ibb.co/VjnFnvF/bayas-Goji.jpg"
                    alt="five slide"
                />
            </Carousel.Item>
            <Carousel.Item className="px-0 px-md-5">
                <Image
                    thumbnail
                    className="d-block w-100 my-4 shadow-lg  mb-5 bg-white rounded"
                    src="https://i.ibb.co/Y7MSnyZ/Palta-Soja.png"
                    alt="First slide"
                />
            </Carousel.Item>
        </Carousel>

    );
}

export default Slider;