import React from 'react';
import { Form, Col } from "react-bootstrap";
import categorias from './../../categorias';

const BusquedaCategoria = ({setCategoriaSeleccionada, categoriaSeleccionada}) => {

    const selectOnChange = e => {
        setCategoriaSeleccionada(e.target.value);
    }

    return(
        <Form className='container'>
            <Form.Row className="justify-content-center">
                <Form.Group className='col-8' controlId="exampleForm.SelectCustom">
                    <Col className="mx-auto mt-3">
                        <Form.Control onChange={selectOnChange} as="select" value={categoriaSeleccionada} className="border-success" custom>
                        {categorias.map((categoria, i) => 
                            (<option 
                                key={i}
                                value={categoria}
                            >
                                {categoria}
                            </option>)
                        )}
                        </Form.Control>
                    </Col>
                </Form.Group>
            </Form.Row>
      </Form>
    )
}

export default BusquedaCategoria;