import React, { useState} from 'react';
import './FormContact.css';
import emailjs from 'emailjs-com';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Form, Button, Col, Container } from 'react-bootstrap';

function FormContact() {
  const [validated, setValidated] = useState(false);
  const apiKeys = {
    TEMPLATE_ID: "formulario",
    USER_ID:"user_m2nUFiNh8a5knxQfC571H",
    accessToken:"431f60f0cec578a616f9382dcb361695"
  }
  
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      sendEmail(event);
    }
  };

  const sendEmail = e => {
    e.preventDefault()
    emailjs
    .sendForm('laboratorio_calcagno', apiKeys.TEMPLATE_ID, e.target, apiKeys.USER_ID)
    .then(
      result => {
        console.log(result.text)
        
    window.location.replace("http://www.laboratoriocalcagno.com.ar")
      },
      error => {
        console.log(error.text)
      }
    )
}




  return (
    <Container>
      <Form noValidate validated={validated} onSubmit={handleSubmit} className="d-flex justify-content-center" >
        <div className="formulariocontacto rounded py-2">
        <Form.Row className="mt-3 mt-md-0 justify-content-center" >
          <Col xs={12}>
          <h4 className="py-2 pb-4 text-center border-success border-bottom  wheretobuyplace" >Contactanos</h4>
         </Col>
        </Form.Row>
        <Form.Row className="mt-3 mt-md-0 justify-content-center" >
          <Col xs={12} sm={12} md={6} lg={6}>
          <Form.Group   controlId="validationCustom01">
            <Form.Label className="font-weight-bold">Nombre</Form.Label>
            <Form.Control
              className="border-success"
              required
              name="nombre"
              type="text"
              placeholder="Nombre"
            />
            <Form.Control.Feedback type="invalid" className="text-danger font-weight-bolder">
              Escribe tu nombre.
                  </Form.Control.Feedback>
          </Form.Group>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
          <Form.Group  controlId="validationCustom02">
            <Form.Label className="font-weight-bold">Apellido</Form.Label>
            <Form.Control
              className="border-success"
              name='apellido'
              required
              type="text"
              placeholder="Apellido"
            />
            <Form.Control.Feedback type="invalid" className="text-danger font-weight-bolder">
              Escribe tu apellido.
                  </Form.Control.Feedback>
          </Form.Group>
        </Col>
        </Form.Row>
        <Form.Row className="justify-content-center">
        <Col xs={12} sm={12} md={6} lg={6}>
          <Form.Group  sm="11" md="6" lg="4" controlId="validationCustom04">
            <Form.Label className="font-weight-bold">Email</Form.Label>
            <Form.Control
              className="border-success"
              name='email'
              required
              type="email"
              placeholder="Email"
            />
            <Form.Control.Feedback type="invalid" className="text-danger font-weight-bolder">
              Escribe un email válido.
                    </Form.Control.Feedback>
          </Form.Group>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
          <Form.Group  controlId="validationCustom05">
            <Form.Label className="font-weight-bold">País</Form.Label>
            <Form.Control
              className="border-success"
              name='pais'
              required
              type="text"
              placeholder="País"
            />
            <Form.Control.Feedback type="invalid" className="text-danger font-weight-bolder">
              Escribe un país válido.
                    </Form.Control.Feedback>
          </Form.Group>
          </Col>
        </Form.Row >
        <Form.Row className="justify-content-center">
          <Col xs={12} sm={12} md={8} lg={8}>
          <Form.Group  controlId="validationCustom06">
            <Form.Label className="font-weight-bold">Provincia</Form.Label>
            <Form.Control
              className="border-success"
              name='provincia'
              required
              type="text"
              placeholder="Provincia"
            />
            <Form.Control.Feedback type="invalid" className="text-danger font-weight-bolder">
              Escribe una provincia válida.
                    </Form.Control.Feedback>
          </Form.Group>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4}>
          <Form.Group  controlId="validationCustom03">
            <Form.Label className="font-weight-bold">Código Postal</Form.Label>
            <Form.Control className="border-success" type="number"  name='codigoPostal' placeholder="Código Postal" required />
            <Form.Control.Feedback type="invalid" className="text-danger font-weight-bolder">
              Escribe un código postal válido.
                </Form.Control.Feedback>
          </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row className="justify-content-center">
        <Col xs={12} sm={12}  md={12} lg={12}>
          <Form.Group  controlId="exampleForm.ControlTextarea">
            <Form.Label className="font-weight-bold">Mensaje</Form.Label>
            <Form.Control
              className="border-success"
              name='msj'
              required
              type="text"
              placeholder="Escribe tu mensaje"
              as="textarea"
              rows="3"
            />
            <Form.Control.Feedback type="invalid" className="text-danger font-weight-bolder">
              Escribe un mensaje.
                </Form.Control.Feedback>
          </Form.Group>
        </Col>
        </Form.Row>
        <div className="text-center ">
          <Button variant="outline-light" className="px-4 py-2 mt-2 border-0 buttonsend font-weight-bold" type="submit"><FontAwesomeIcon icon={faEnvelope} className="mr-2" />Enviar</Button>
        </div>
      </div>
      </Form>
    </Container>

  );
}

export default FormContact;