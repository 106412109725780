const Dermatologia = [
    {
        "nombre":"Actigym crema x 100 gr",
        "descripcion":"Formulación de acción lipolítica y modeladora con óptimo deslizamiento para una aplicación rápida y previa al ejercicio físico. Mejora el tono muscular.",
        "contraindicaciones":"",
        "contenido":"",
        "urlImg":"https://i.ibb.co/hLjJBdr/actigym.jpg",
        "composicion":"",  
    },
    {
        "nombre":"Actigym Face crema x 60 gr",
        "descripcion":"Formulación de acción lipolítica y modeladora. Mejora el tono muscular del rostro.",
        "contraindicaciones":"",
        "contenido":"",
        "urlImg":"https://i.ibb.co/d06KS0g/ACTYGYM-FACE-X-60-GR.jpg",
        "composicion":"",   
    },
    {
        "nombre":"Aclarante antiage contorno de ojos x 10 ml",
        "descripcion":"contiene una innovadora fórmula desarrollada en forma exclusiva por nuestro laboratorio, que permite tratar de forma conjunta Ojeras y Bolsas Palpebrales a través de principios activos de probada eficacia y siguiendo los últimos avances, ESTIMULANDO LA SINTESIS DE COLAGENO Y PREVIENE SU DEGRADACIÓN- ANTIOXIDANTE- DESCONGESTIVO- ACLARANTE - Y SUAVIZA LAS ARRUGAS Y LINEAS FINAS.",
        "contraindicaciones":"",
        "contenido":"",
        "urlImg":"https://i.ibb.co/1RcWHVr/ACLARANTE-ANTIAGE-CONTORNO-DE-OJOS-png.jpg",
        "composicion":"",   
    },
    {
        "nombre":"Adifyline crema x 60 gr ",
        "descripcion":"Incrementa el volumen de tejido adiposo para obtener volumen y curvas por lo que va dirigido a las zonas específicas de la cara y el cuerpo más vulnerables a la fuerza de la gravedad y al paso del tiempo.",
        "contraindicaciones":"",
        "contenido":"",
        "urlImg":"https://i.ibb.co/YbH8gC5/ARGIRELINE-CREMA.jpg",
        "composicion":"",   
    },
    {
        "nombre":"Agua termal x 130 ml",
        "descripcion":"Aumenta la sensación de frescor, disminuye la impresión de tensión de la piel y favorece la suavidad y bienestar de la piel. \n Además de suavizar y refrescar la piel, es muy recomendable, por ejemplo, tras la depilación o la aplicación de algún tratamiento estético por sus propiedades calmantes. ... Descongestiona el rostro y refuerza la protección natural de la piel. El agua termal alivia también las rojeces, irritaciones y picores de la piel.",
        "contraindicaciones":"",
        "contenido":"",
        "urlImg":"https://i.ibb.co/n7nrLNj/AGUA-TERMAL.jpg",
        "composicion":"",   
    },{
        "nombre":"Agua de Mar x 130 ml ",
        "descripcion":"El agua de mar para la piel tiene propiedades antisépticas, aporta nutrientes esenciales y actúa como un exfoliante natural. \n Tiene propiedades de acción antibacteriana,  antisépticas , ayuda a curar y cicatrizar pequeñas heridas. También cuenta propiedades exfoliantes, que contribuyen a mantener la piel limpia y libre de toxinas. También favorece la renovación celular , mejora la circulación y su alto contenido en minerales favorece la nutrición de la piel.",
        "contraindicaciones":"",
        "contenido":"",
        "urlImg":"https://i.ibb.co/c8MMdmb/AGUA-DE-MAR.jpg",
        "composicion":"",   
    },{
        "nombre":"Agua de Caléndula x 130 ml",
        "descripcion":"Es cicatrizante y antiséptica. sirve para curar heridas, estimula la acción cicatrizante y regenera la piel dañada  pero, además, también produce más colágeno (fundamental para que la piel se recupere) Tiene propiedades emolientes, calmantes y suavizantes.  \n Es antiinflamatoria. Reduce la inflamación.",
        "contraindicaciones":"",
        "contenido":"",
        "urlImg":"https://i.ibb.co/tmVcqPY/AGUA-DE-CALENDULA.jpg",
        "composicion":"",   
    },{
        "nombre":"Agua de Rosas x 200 ml",
        "descripcion":"Sirve como desinflamante y es antibacterial. Puedes aplicarla como tratamiento para reducir la irritación y combatir los granitos y puntos negros de la piel. Hidratante de pieles sensibles. Protege, restaura e hidrata la dermis, además de equilibrar los niveles de pH de la piel, reduciendo los brotes y previniendo cualquier sequedad.",
        "contraindicaciones":"",
        "contenido":"",
        "urlImg":"https://i.ibb.co/sQrNYn2/AGUA-DE-ROSAS.jpg",
        "composicion":"",   
    },{
        "nombre":"Agua de coco antiage x 130 ml",
        "descripcion":"Hidratante y nutritivo \n Contiene vitaminas muy importantes para la piel, como son las vitaminas A, B y E, las cuales ayudan a evitar la deshidratación de los tejidos y la pérdida de agua que, entre otras cosas, puede provocar sequedad y envejecimiento prematuro. Además, el agua de coco es muy ligero y consigue penetrar profundamente en la piel, llegando hasta las capas más internas, por lo que es altamente efectivo cuando se trata de nutrirla. Por otro lado, se ha señalado que es un excelente remedio para tratar de forma natural afecciones cutáneas, como la psoriasis, la dermatitis o el eccema. Antiage gracias a las elevadas dosis que aporta de antioxidantes y vitamina E. Contiene unas hormonas vegetales llamadas citoquininas que neutralizan la acción de los radicales libres en el cuerpo, los principales responsables de la oxidación de las células y, por ende, del envejecimiento de la piel. Esto sumado al aporte de vitamina E hace que sea ideal para favorecer la regeneración celular de la dermis, prevenir la aparición de arrugas y reducir la visibilidad de las ya existentes.",
        "contraindicaciones":"",
        "contenido":"",
        "urlImg":"https://i.ibb.co/rx8hvgT/AGUA-DE-COCO.jpg",
        "composicion":"",   
    },{
        "nombre":"Argireline crema antiage x 30 gr",
        "descripcion":"Crema antienvejecimiento con argireline para todo tipo de pieles, hombres y mujeres con 'efecto Botox' \n Crema Arigirelinee antiarrugas eficaz que previene y reduce las líneas de expresión en la frente, cejas y alrededor de los ojos. \n 'Efecto natural del botox sobre la piel.'",
        "contraindicaciones":"",
        "contenido":"",
        "urlImg":"https://i.ibb.co/YbH8gC5/ARGIRELINE-CREMA.jpg",
        "composicion":"",   
    },{
        "nombre":"Bálsamo labial de maracuyá x 15 gr",
        "descripcion":"Exquisito Bálsamo de Labios full hidratante. Es un bálsamo reparador y protector del calor o el frío.",
        "contraindicaciones":"",
        "contenido":"",
        "urlImg":"https://i.ibb.co/9wnWQKh/BALSAMOL-LABIAL.jpg",
        "composicion":"",   
    },{
        "nombre":"Capsulas anticelulíticas x 40 ",
        "descripcion":"Reduce la celulitis. Ayuda a perder el 5% del volumen y circunferencia de los muslos. Potente antioxidante. Estimula la circulación y es útil para el tratamiento de manifestaciones como sensación de pesadez, hormigueo, dolor y calambres nocturnos. Efecto estimulador del drenaje linfático. Aumenta el metabolismo general.",
        "contraindicaciones":"",
        "contenido":"",
        "urlImg":"https://i.ibb.co/z87s9JD/CAPSULAS-ANTICELULITIS.jpg",
        "composicion":"",   
    },{
        "nombre":"Capsulas bronceadoras x 30 ",
        "descripcion":"El poder de estas cápsulas es que aceleran el proceso de aparición del moreno e intensifican su efecto, siempre y cuando no olvidemos protegernos con un protector adecuado. Además, nos aportan una hidratación extra, aumenta nuestra tolerancia al sol (previniendo un envejecimiento prematuro de la piel) y nos ayudan a lograr un bronceado uniforme.",
        "contraindicaciones":"",
        "contenido":"",
        "urlImg":"https://i.ibb.co/VYdh6QD/CAPSULAS-BRONCEADORAS.jpg",
        "composicion":"",   
    },{
        "nombre":"Colágeno bebible x 60 ml ",
        "descripcion":"Es un suplemento dietario, Su función es nutrir la piel desde el interior, garantizando su regeneración con el fin de que luzca saludable y joven.",
        "contraindicaciones":"",
        "contenido":"",
        "urlImg":"https://i.ibb.co/VNDMR7D/COLAGENO-BEBIBLE.jpg",
        "composicion":"",   
    },{
        "nombre":"Colágeno Belle polvo x 100 gr",
        "descripcion":"Poderoso antioxidante, incrementa la renovación celular, estimula la producción de colágeno y elastina, disminuye las grasas y el colesterol en la sangre, ayuda a eliminar las toxinas.",
        "contraindicaciones":"",
        "contenido":"",
        "urlImg":"https://i.ibb.co/MR1nf8D/COLAGENO-BELLE.jpg",
        "composicion":"",   
    },{
        "nombre":"Colageno hidrolizado , polvo x 100 gramos",
        "descripcion":"El colágeno hidrolizado es un suplemento alimenticio preparado a partir de huesos y cartílagos bovinos. Es utilizado para estimular la producción de colágeno en el organismo, ayudando así a mejorar el aspecto de la piel, fortalecer las articulaciones, las uñas, los huesos y el cabello.",
        "contraindicaciones":"",
        "contenido":"",
        "urlImg":"https://i.ibb.co/D5XcZMp/COLAGENO-HIDROLIZADO-POLVO.jpg",
        "composicion":"",   
    },{
        "nombre":"Colageno 500 mg x 30 caps",
        "descripcion":"El colágeno ayuda a prevenir la aparición de celulitis, fortalece las uñas, el cabello, aumenta la elasticidad de la piel y previene y retarda el surgimiento de arrugas y líneas de expresión.",
        "contraindicaciones":"",
        "contenido":"",
        "urlImg":"https://i.ibb.co/Bfs52MS/COLAGENO-CAPSULAS.jpg",
        "composicion":"",   
    },{
        "nombre":"Crema abrasiva de limpieza",
        "descripcion":"Limpiador abrasivo para diferentes tipos de pieles. Contiene microgránulos que emulsionan el flujo excesivo de sebo que obstruye los poros de la piel. Es un limpiador astringente.Emulsifica, disuelve y elimina las células descamadas que bloquean la superficie de la piel.",
        "contraindicaciones":"",
        "contenido":"",
        "urlImg":"https://i.ibb.co/X5VJYCm/CREMA-ABRASIVA-DE-LIMPIEZA.jpg",
        "composicion":"",   
    },{
        "nombre":"Crema antiage celulas madre ",
        "descripcion":"Crema desarrollada sobre la base de extractos de células madres vegetales (Malus Domesticas PCT) que hidrata, protege y repone las células madres de la piel y relentiza los efectos estéticos del envejecimiento cutáneo ayudando a prevenir las aparición de arrugas o atenuando la profundidad de las mismas, conservando la función y longevidad a las células madres de la piel. Esta indicada para pieles maduras.",
        "contraindicaciones":"",
        "contenido":"",
        "urlImg":"https://i.ibb.co/qnrX2pF/CREMA-CELULA-MADRE.jpg",
        "composicion":"",   
    },{
        "nombre":"Emulgel acelerador del bronceado",
        "descripcion":"Emulsión Liviana Aceleradora del Bronceado, Sin tono de Bronceado Instantáneo. Indicado para Pieles normales a mixtas, tanto Rostro como Cuerpo. Recomendado para mejorar el bronceado con Menos Exposición Solar. se puede usar tanto al Sol como en la Cama Solar, Se Aconseja Combinar con Protector solar.",
        "contraindicaciones":"",
        "contenido":"",
        "urlImg":"https://i.ibb.co/fqGP8cC/EMULSION-ACELERADOR-DEL-BRONCEADO.jpg",
        "composicion":"",   
    },{
        "nombre":"Espuma anti acné",
        "descripcion":"La delicada espuma limpiadora regula el exceso de oleosidad y es ideal para pieles impuras y propensas al acné. Su excelente formulación permite  limpiar y purificar la piel dejándola sana y libre de restos impuros. Además calma y refresca los tipos de piel problemáticos.",
        "contraindicaciones":"",
        "contenido":"",
        "urlImg":"https://i.ibb.co/q9pg4DC/ESPUMA-ANTIACNE.jpg",
        "composicion":"",   
    },{
        "nombre":"Emulsión orthomolecular",
        "descripcion":"Biorevitalizante y reparadora celular. Hidrata, mejora el  aspecto de la piel aportando turgencia y tensión.  Emulsión liviana, con efecto hidratante y antioxidante que gracias a su increíble formulación  ejerce un efecto antiage potente para el rostro.",
        "contraindicaciones":"",
        "contenido":"",
        "urlImg":"https://i.ibb.co/y4F052X/EMULSION-ORTHOMOLECULAR.jpg",
        "composicion":"",   
    },{
        "nombre":"Emulsión anticelulitis",
        "descripcion":"Ejerce una intensa acción anticelulítica y reductora corporal. Ayuda a reducir notablemente y en muy poco tiempo de tratamiento la celulitis y la grasa localizada. Activa ayudando a modelar en forma segura y eficaz el contorno corporal.",
        "contraindicaciones":"",
        "contenido":"",
        "urlImg":"https://i.ibb.co/dPhhFkc/EMULSION-ANTICELULITIS.jpg",
        "composicion":"",   
    },{
        "nombre":"Jeringa VITAMINA C",
        "descripcion":"HIDRATANTE",
        "contraindicaciones":"",
        "contenido":"",
        "urlImg":"https://i.ibb.co/F8hTvBh/JERINGAS-MODIFICADAS.jpg",
        "composicion":"",   
    },{
        "nombre":"Jeringa RETINOL DE SEDA",
        "descripcion":"Regeneradora celular, antiarrugas, estimulante de la produccion de colageno, elastina y acido hialuronico.",
        "contraindicaciones":"",
        "contenido":"",
        "urlImg":"https://i.ibb.co/R3DRrCw/Sin-t-tulo-1.jpg",
        "composicion":"",   
    },{
        "nombre":"Jeringa de Acido Hialuronico",
        "descripcion":"Redensificante y antiarrugas.",
        "contraindicaciones":"",
        "contenido":"",
        "urlImg":"https://i.ibb.co/2MfXh95/Sin-t-tulo-2.jpg",
        "composicion":"",   
    },{
        "nombre":"Emulsión de MARACUYA",
        "descripcion":"Antioxidante, revitalizante de la piel, regeneradora post - solar.",
        "contraindicaciones":"",
        "contenido":"",
        "urlImg":"https://i.ibb.co/mzTpxqv/MARACUYA-EMULSION.jpg",
        "composicion":"",   
    },{
        "nombre":"Mascara antiage con colágeno y elastina x 60 gr",
        "descripcion":"Mascara facial , con propiedades antiage y redensificante de la piel.",
        "contraindicaciones":"",
        "contenido":"",
        "urlImg":"https://i.ibb.co/zhryf87/REDESINFICANTE-CREMA.jpg",
        "composicion":"",   
    },{
        "nombre":"Máscara de chocolate x 60 gr",
        "descripcion":"Mascara para tratamiento facial, antioxidante, hidratante, y revitalizante.",
        "contraindicaciones":"",
        "contenido":"",
        "urlImg":"https://i.ibb.co/PGVmssh/MASCARA-DE-CHOCOLATE.jpg",
        "composicion":"",   
    },{
        "nombre":"Mascara Detox  x 60 gr",
        "descripcion":"Máscara para tratamiento facial, con arcillas blancas, verde y carbón activado. Es depurativa y tonificante.",
        "contraindicaciones":"",
        "contenido":"",
        "urlImg":"https://i.ibb.co/gb26nQx/MASCARA-DETOX.jpg",
        "composicion":"",   
    },{
        "nombre":"Mascara de Maracuya x 60 gr",
        "descripcion":"Máscara para tratamiento facial con acción antioxidante de la piel.",
        "contraindicaciones":"",
        "contenido":"",
        "urlImg":"https://i.ibb.co/sbsBWMv/MASCARA-DE-MARACUYA.jpg",
        "composicion":"",   
    },{
        "nombre":"Crema Redensificante x 60 gr antiage",
        "descripcion":"crema facial bioregeneradora y de hidratación profunda. suaviza y perfecciona la piel.",
        "contraindicaciones":"",
        "contenido":"",
        "urlImg":"https://i.ibb.co/LdTsGFw/SERUM-ORTHOMOLECULAR.jpg",
        "composicion":"",   
    },{
        "nombre":"Serum orthomolecular  x20 ml",
        "descripcion":"Serum antiage, hidratante, regenerador de la piel . su uso está indicado en las zonas del párpado móvil y fijo del ojo.",
        "contraindicaciones":"",
        "contenido":"",
        "urlImg":"https://i.ibb.co/LdTsGFw/SERUM-ORTHOMOLECULAR.jpg",
        "composicion":"",   
    },{
        "nombre":"Vitalite antiage crema x 20 gr",
        "descripcion":"crema antiage intensivo con Factor de protección solar 15.",
        "contraindicaciones":"",
        "contenido":"",
        "urlImg":"https://i.ibb.co/MgTKfws/VITALITE.jpg",
        "composicion":"",   
    }
];

export default Dermatologia;