import React from 'react';
import ListadoCategoria from './../ListadoCategoria/ListadoCategoria';

const ListadoMedicamentos = ({medicamentosParaListar, categoriaSeleccionada, setCategoriaSeleccionada, setBusquedaProducto}) => {
    return(
        <div className='container'>
        {
            categoriaSeleccionada === "Todas" ?
            (
                <ListadoCategoria
                        categoria={categoriaSeleccionada}
                        medicamentosParaListar={medicamentosParaListar.sort((a, b) => a.nombre.localeCompare(b.nombre))}
                        setCategoriaSeleccionada = {setCategoriaSeleccionada}
                />
            )
            :
            (
                <ListadoCategoria
                        categoria={categoriaSeleccionada}
                        medicamentosParaListar={medicamentosParaListar}
                        setCategoriaSeleccionada = {setCategoriaSeleccionada}
                        setBusquedaProducto={setBusquedaProducto}
                />
            )
        }
        </div>
    )
}

export default ListadoMedicamentos;