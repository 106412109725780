import React from 'react'
import { Card, Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion'; 

const pageTransition = {
    in: {
        opacity: 1,
        y:0
},
    out: {
        opacity: 0,
        x: "0%"
}
}

export default function Brs() {
    return (
        <motion.div initial="out" animate="in" exit="out" variants={pageTransition}>
            <Card className="text-center">
                <Card.Body>
                    <Row>
                    <Col lg={4}>
                            <Card className="border-0">
                                <Card.Header className="border-0 wheretobuyplace font-weight-bolder">
                                Farmacia De la Rosa (Independencia)
                                    </Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                    Av. Independencia 390 <Button type="link" href="https://www.google.com.ar/maps/place/Farmacia+de+la+Rosa/@-26.8349944,-65.1670574,17z/data=!3m1!4b1!4m5!3m4!1s0x94225ecad202f813:0xc9c891e9bd615ebc!8m2!3d-26.8349944!4d-65.1648687" target="_blank" variant="success" className="ml-2 mb-1 pb-2 border-0 bg-transparent  zoombottomsmap"><FontAwesomeIcon className="fa-lg" icon={faMapMarkedAlt} /></Button>
                                    </Card.Text>

                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4}>
                            <Card className="border-0">
                                <Card.Header className="border-0 wheretobuyplace font-weight-bolder">
                                Farmacia De la Rosa
                                    </Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                    9 de Julio 214 <Button type="link" href="https://www.google.com.ar/maps/place/Farmacia+de+la+Rosa+Centro/@-26.8384071,-65.1641916,17z/data=!3m1!4b1!4m5!3m4!1s0x9422593462b5a487:0x24aaf43234efacf3!8m2!3d-26.8384071!4d-65.1620029" target="_blank" variant="success" className="ml-2 mb-1 pb-2 border-0 bg-transparent  zoombottomsmap"><FontAwesomeIcon className="fa-lg" icon={faMapMarkedAlt} /></Button>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4}>
                            <Card className="border-0">
                                <Card.Header className="border-0 wheretobuyplace font-weight-bolder">
                                Farmacia De la Rosa (Sur)
                                    </Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                    Sargento Cabral 181 <Button type="link" href="https://www.google.com.ar/maps/place/Farmacia+De+La+Rosa+Sur/@-26.8399105,-65.1683643,17z/data=!4m8!1m2!2m1!1sFarmacia+de+la+Rosa+sur,+Banda+del+R%C3%ADo+Sal%C3%AD,+Tucum%C3%A1n!3m4!1s0x0:0x312f8e5726200659!8m2!3d-26.8418011!4d-65.1666243" target="_blank" variant="success" className="ml-2 mb-1 pb-2 border-0 bg-transparent  zoombottomsmap"><FontAwesomeIcon className="fa-lg" icon={faMapMarkedAlt} /></Button>
                                    </Card.Text>

                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer className="border-0 wheretobuyplace font-weight-bolder ">Banda del Rio Salií</Card.Footer>
            </Card>
        </motion.div>
    )
}
