import React, { Fragment } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import Medicamento from './../Medicamento/Medicamento';
import whatsappImg from './../../img/whatsapp.png';

const ListadoCategoria = ({ categoria, medicamentosParaListar, setCategoriaSeleccionada }) => {

    const phone = "5493816790209";
    const msg = "Escriba%20su%20mensaje...";
    const whatsAppLink = `https://api.whatsapp.com/send?phone=${phone}&text=${msg}`;

    return (
        <Fragment>
            {
                categoria === 'TODAS' ?
                    ("")
                    :
                    (<div className='mb-4 p-2'>
                        {medicamentosParaListar.length === 0 ?
                            (
                                <div>
                                    <h1 className="py-5 text-white">Lo sentimos!</h1>
                                    <h3 className="py-4 text-white">No tenemos actualmente productos con ese nombre</h3>
                                    <h3 className="text-white py-4">Por cualquier consulta comunicarse con nuestro laboratorio, haciendo click
                                        <a className="bg-transparent border-0 " href={whatsAppLink} target="_blank" rel="noopener noreferrer">
                                            <Image src={whatsappImg} className="ml-2" width="40" height="40" />
                                        </a>
                                    </h3>
                                    <br />
                                    <h5 className="py-5 text-white">Intente buscando en nuestro catálogo</h5>
                                </div>)
                            :
                            (<Row>
                                {
                                    (medicamentosParaListar.map((medicamento, i) => (
                                        <Col sm={12} lg={3} md={4} key={i}>
                                            <Medicamento
                                                idMedicamentoLink={medicamento.nombre}
                                                medicamento={medicamento}
                                                setCategoriaSeleccionada={setCategoriaSeleccionada}
                                            />
                                        </Col>
                                    )))
                                }

                            </Row>)}
                    </div>)
            }
        </Fragment>
    );
}

export default ListadoCategoria;