import React from 'react'
import { Card, Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion'; 

const pageTransition = {
    in: {
        opacity: 1,
        y:0
},
    out: {
        opacity: 0,
        x: "0%"
}
}

export default function Smt() {
    
    return (
        <motion.div initial="out" animate="in" exit="out" variants={pageTransition}>

            <Card className="text-center">
                <Card.Body>
                    <Row >
                        <Col lg={4}>
                            <Card className="border-0">
                                <Card.Header className="border-0 wheretobuyplace font-weight-bolder ">
                                    Farmacia Calcagno
                                    </Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        Av. Fco. de Aguirre <Button type="link" href="https://www.google.com.ar/maps/place/Farmacia+Calcagno/@-26.7919746,-65.2142076,17z/data=!3m1!4b1!4m5!3m4!1s0x94225dc857458053:0xdd6c840c2169137f!8m2!3d-26.7919794!4d-65.2120189" target="_blank" className="ml-2 mb-1 pb-2 border-0 bg-transparent  zoombottomsmap"><FontAwesomeIcon className="fa-lg" icon={faMapMarkedAlt} /></Button>
                                    </Card.Text>

                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4}>
                            <Card className="border-0 ">
                                <Card.Header className="border-0 wheretobuyplace font-weight-bolder ">
                                    Farmacia Namuncurá
                                    </Card.Header>
                                <Card.Body>

                                    <Card.Text>
                                        Córdoba 571<Button type="link" href="https://www.google.com.ar/maps/place/Farmacia+Namuncur%C3%A1/@-26.8266432,-65.2068097,17z/data=!3m1!4b1!4m5!3m4!1s0x94225c1733c11819:0x38f3498b3d0f010a!8m2!3d-26.826648!4d-65.204621" target="_blank" className="ml-2 mb-1 pb-2 border-0 bg-transparent zoombottomsmap"><FontAwesomeIcon className="fa-lg" icon={faMapMarkedAlt} /></Button>
                                    </Card.Text>

                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4}>
                            <Card className="border-0 ">
                                <Card.Header className="border-0 wheretobuyplace font-weight-bolder ">
                                    Farmacia San Andrés
                                    </Card.Header>
                                <Card.Body>

                                    <Card.Text>
                                        Av. Colón 1162 <Button type="link" href="https://www.google.com.ar/maps/place/Av.+Col%C3%B3n+1162,+San+Miguel+de+Tucum%C3%A1n,+Tucum%C3%A1n/@-26.8410534,-65.2380154,17z/data=!3m1!4b1!4m5!3m4!1s0x94225c7d09e668e3:0xc78e3c2260785568!8m2!3d-26.8410534!4d-65.2358267" target="_blank" className="ml-2 mb-1 pb-2 border-0 bg-transparent zoombottomsmap"><FontAwesomeIcon className="fa-lg" icon={faMapMarkedAlt} /></Button>
                                    </Card.Text>

                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={12}>
                            <Card className="border-0 ">
                                <Card.Header className="border-0 wheretobuyplace font-weight-bolder ">
                                    Farmacias Del Pueblo
                                    </Card.Header>
                                <Card.Body>
                                    <Row className="d-flex justify-content-center">
                                        <Col sm={4}>
                                            <Card.Text>
                                                Av. Alem 199 <Button type="link" href="https://www.google.com.ar/maps/place/Farmacias+Del+Pueblo/@-26.8308757,-65.2199792,19z/data=!4m12!1m6!3m5!1s0x94225c6c15911ac3:0x5f7fe4986d811916!2sFarmacias+Del+Pueblo!8m2!3d-26.8308769!4d-65.219432!3m4!1s0x94225c6c15911ac3:0x5f7fe4986d811916!8m2!3d-26.8308769!4d-65.219432" target="_blank" className="ml-2 mb-1 pb-2 border-0 bg-transparent zoombottomsmap"><FontAwesomeIcon className="fa-lg" icon={faMapMarkedAlt} /></Button>
                                            </Card.Text>
                                        </Col>
                                        <Col sm={4}>
                                            <Card.Text>
                                                Laprida 3 <Button type="link" href="https://www.google.com.ar/maps/place/Laprida+3,+T4000IFA+San+Miguel+de+Tucum%C3%A1n,+Tucum%C3%A1n/@-26.8310433,-65.2053437,17z/data=!3m1!4b1!4m5!3m4!1s0x94225c100cd86723:0x60f31981cbaad399!8m2!3d-26.8310433!4d-65.203155" target="_blank" className="ml-2 mb-1 pb-2 border-0 bg-transparent zoombottomsmap"><FontAwesomeIcon className="fa-lg" icon={faMapMarkedAlt} /></Button>
                                            </Card.Text>
                                        </Col>
                                        <Col sm={4}>
                                            <Card.Text>
                                                Maipu 27 <Button type="link" href="https://www.google.com.ar/maps/place/Maip%C3%BA+27,+T4000+San+Miguel+de+Tucum%C3%A1n,+Tucum%C3%A1n/@-26.8301449,-65.2098706,17z/data=!3m1!4b1!4m5!3m4!1s0x94225c11773e9af3:0xd1960b15091dfb8a!8m2!3d-26.8301449!4d-65.2076819" target="_blank" className="ml-2 mb-1 pb-2 border-0 bg-transparent zoombottomsmap"><FontAwesomeIcon className="fa-lg" icon={faMapMarkedAlt} /></Button>
                                            </Card.Text>
                                        </Col>
                                        <Col sm={4}>
                                            <Card.Text>
                                                25 de Mayo 313 <Button type="link" href="https://www.google.com.ar/maps/place/25+de+Mayo+313,+T4000+San+Miguel+de+Tucum%C3%A1n,+Tucum%C3%A1n/@-26.8267914,-65.2058808,17z/data=!3m1!4b1!4m5!3m4!1s0x94225c174d331297:0xbd56729b2880858!8m2!3d-26.8267914!4d-65.2036921" target="_blank" className="ml-2 mb-1 pb-2 border-0 bg-transparent zoombottomsmap"><FontAwesomeIcon className="fa-lg" icon={faMapMarkedAlt} /></Button>
                                            </Card.Text>
                                        </Col>
                                        <Col sm={4}>
                                            <Card.Text>
                                                Av. Colón 596 <Button type="link" href="https://www.google.com.ar/maps/place/Av.+Col%C3%B3n+596,+T4000+San+Miguel+de+Tucum%C3%A1n,+Tucum%C3%A1n/@-26.8332073,-65.235866,17z/data=!3m1!4b1!4m5!3m4!1s0x94225c63e80ca1f9:0x864116f2cf3a905a!8m2!3d-26.8332073!4d-65.2336773" target="_blank" className="ml-2 mb-1 pb-2 border-0 bg-transparent zoombottomsmap"><FontAwesomeIcon  className="fa-lg" icon={faMapMarkedAlt} /></Button>
                                            </Card.Text>
                                        </Col>
                                        <Col sm={4}>
                                            <Card.Text>
                                                Av. Belgrano 2847 <Button type="link" href="https://www.google.com.ar/maps/place/Av.+Manuel+Belgrano+2847,+T4000+San+Miguel+de+Tucum%C3%A1n,+Tucum%C3%A1n/@-26.8115099,-65.2396087,17z/data=!3m1!4b1!4m5!3m4!1s0x94225c560f4db855:0x4c765c521301699f!8m2!3d-26.8115099!4d-65.23742" target="_blank" className="ml-2 mb-1 pb-2 border-0 bg-transparent zoombottomsmap"><FontAwesomeIcon  className="fa-lg" icon={faMapMarkedAlt} /></Button>
                                            </Card.Text>
                                        </Col>
                                        <Col sm={4}>
                                            <Card.Text>
                                                Av. Avellaneda 268 <Button type="link" href="https://www.google.com.ar/maps/place/Av.+Avellaneda+268,+T4000AGP+San+Miguel+de+Tucum%C3%A1n,+Tucum%C3%A1n/@-26.8288424,-65.1962502,19.08z/data=!4m5!3m4!1s0x94225c1c83f5c45f:0x8bb577f10aa280!8m2!3d-26.8286701!4d-65.1964183" target="_blank" className="ml-2 mb-1 pb-2 border-0 bg-transparent zoombottomsmap"><FontAwesomeIcon className="fa-lg"  icon={faMapMarkedAlt} /></Button>
                                            </Card.Text>
                                        </Col>
                                        <Col sm={4}>
                                            <Card.Text>
                                                Córdoba 652 <Button type="link" href="https://www.google.com.ar/maps/place/Farmacias+Del+Pueblo/@-26.826633,-65.2070196,18z/data=!3m1!4b1!4m5!3m4!1s0x94225c16d9fef71f:0x382a44e86127c9a5!8m2!3d-26.8266354!4d-65.2059253" target="_blank" className="ml-2 mb-1 pb-2 border-0 bg-transparent zoombottomsmap"><FontAwesomeIcon className="fa-lg" icon={faMapMarkedAlt} /></Button>
                                            </Card.Text>
                                        </Col>
                                        <Col sm={4}>
                                            <Card.Text>
                                                Jujuy 120 <Button type="link" href="https://www.google.com.ar/maps/place/Farmacias+Del+Pueblo/@-26.8313022,-65.2133257,17z/data=!3m1!4b1!4m5!3m4!1s0x94225c1251837cdf:0x43e6b0cd356159f1!8m2!3d-26.831307!4d-65.211137" target="_blank" className="ml-2 mb-1 pb-2 border-0 bg-transparent zoombottomsmap"><FontAwesomeIcon className="fa-lg" icon={faMapMarkedAlt} /></Button>
                                            </Card.Text>
                                        </Col>
                                        <Col sm={4}>
                                            <Card.Text>
                                                9 de Julio 9 <Button type="link" href="https://www.google.com.ar/maps/place/9+de+Julio+9,+T4000IHA+San+Miguel+de+Tucum%C3%A1n,+Tucum%C3%A1n/@-26.831053,-65.2069717,17z/data=!3m1!4b1!4m5!3m4!1s0x94225c10462e2f15:0x2c61a041e1126b57!8m2!3d-26.831053!4d-65.204783" target="_blank" className="ml-2 mb-1 pb-2 border-0 bg-transparent zoombottomsmap"><FontAwesomeIcon  className="fa-lg" icon={faMapMarkedAlt} /></Button>
                                            </Card.Text>
                                        </Col>
                                        <Col sm={4}>
                                            <Card.Text>

                                            </Card.Text>
                                        </Col>
                                        <Col sm={4}>
                                            <Card.Text>
                                                Virgen de la Merced 600 <Button type="link" href="https://www.google.com/" target="_blank" className="ml-2 mb-1 pb-2 border-0 bg-transparent zoombottomsmap"><FontAwesomeIcon className="fa-lg" icon={faMapMarkedAlt} /></Button>
                                            </Card.Text>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4}>
                            <Card className="border-0 ">
                                <Card.Header className="border-0 wheretobuyplace font-weight-bolder ">
                                    Farmacia Nueva
                                    </Card.Header>
                                <Card.Body>

                                    <Card.Text>
                                        Lavalle 999  <Button type="link" href="https://www.google.com.ar/maps/place/Farmacia+Nueva/@-26.8372572,-65.2164702,17z/data=!3m1!4b1!4m5!3m4!1s0x94225c0cd3c96b03:0xbe5d359979d2e9d4!8m2!3d-26.837262!4d-65.2142815" target="_blank" className="ml-2 mb-1 pb-2 border-0 bg-transparent zoombottomsmap"><FontAwesomeIcon className="fa-lg" icon={faMapMarkedAlt} /></Button>
                                    </Card.Text>

                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4}>
                            <Card className="border-0 ">
                                <Card.Header className="border-0 wheretobuyplace font-weight-bolder ">
                                    Farmacia Orihuel

                                    </Card.Header>
                                <Card.Body>

                                    <Card.Text>
                                        Av. Mitre 869  <Button type="link" href="https://www.google.com.ar/maps/place/Farmacia+Orihuel/@-26.8166615,-65.2175167,17z/data=!3m1!4b1!4m5!3m4!1s0x94225c4742692871:0x6aca73ed60778422!8m2!3d-26.8166663!4d-65.215328" target="_blank" className="ml-2 mb-1 pb-2 border-0 bg-transparent zoombottomsmap"><FontAwesomeIcon className="fa-lg"  icon={faMapMarkedAlt} /></Button>
                                    </Card.Text>

                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4}>
                            <Card className="border-0 ">
                                <Card.Header className="border-0 wheretobuyplace font-weight-bolder ">
                                    Farmacia San Isidro

                                    </Card.Header>
                                <Card.Body>

                                    <Card.Text>
                                        Jujuy 3255  <Button type="link" href="https://www.google.com.ar/maps/place/Farmacia+San+Isidro/@-26.8704255,-65.2243492,17z/data=!3m1!4b1!4m5!3m4!1s0x94225bbdbe6249a5:0x51e6576bab0890fc!8m2!3d-26.8704303!4d-65.2221605" target="_blank" className="ml-2 mb-1 pb-2 border-0 bg-transparent zoombottomsmap"><FontAwesomeIcon className="fa-lg" icon={faMapMarkedAlt} /></Button>
                                    </Card.Text>

                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4}>
                            <Card className="border-0 ">
                                <Card.Header className="border-0 wheretobuyplace font-weight-bolder ">
                                    Botánica Anahi

                                    </Card.Header>
                                <Card.Body>

                                    <Card.Text>
                                        Francia 60   <Button type="link" href="https://www.google.com.ar/maps/place/Bot%C3%A1nica+Anahi/@-26.8311973,-65.1979565,17z/data=!3m1!4b1!4m5!3m4!1s0x94225eadf53b52df:0xed5ce35f12df12a9!8m2!3d-26.8312021!4d-65.1957678" target="_blank" className="ml-2 mb-1 pb-2 border-0 bg-transparent zoombottomsmap"><FontAwesomeIcon className="fa-lg" icon={faMapMarkedAlt} /></Button>
                                    </Card.Text>

                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4} >
                            <Card className="border-0 ">
                                <Card.Header className="border-0 wheretobuyplace font-weight-bolder ">
                                    Herboristería Anatai

                                    </Card.Header>
                                <Card.Body>

                                    <Card.Text >
                                    Córdoba 641  <Button type="link" href="https://www.google.com.ar/maps/place/Herborister%C3%ADa+Anatai/@-26.8264669,-65.2079026,17z/data=!3m1!4b1!4m5!3m4!1s0x94225c16d84c6a4b:0xb337469440281fd1!8m2!3d-26.8264717!4d-65.2057139" target="_blank" className="ml-2 mb-1 pb-2 border-0 bg-transparent zoombottomsmap"><FontAwesomeIcon className="fa-lg" icon={faMapMarkedAlt} /></Button>
                                    </Card.Text>

                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4}>
                            <Card className="border-0 ">
                                <Card.Header className="border-0 wheretobuyplace font-weight-bolder ">
                                    Herboristería Odagui

                                    </Card.Header>
                                <Card.Body>

                                    <Card.Text>
                                    24 de Septiembre 636  <Button type="link" href="https://www.google.com.ar/maps/place/Herborister%C3%ADa+Odagui/@-26.8307033,-65.2089587,17z/data=!3m1!4b1!4m5!3m4!1s0x94225c11a476cf91:0xabce9a1b6c668858!8m2!3d-26.8307081!4d-65.20677" target="_blank" className="ml-2 mb-1 pb-2 border-0 bg-transparent zoombottomsmap"><FontAwesomeIcon className="fa-lg" icon={faMapMarkedAlt} /></Button>
                                    </Card.Text>

                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer className="border-0 wheretobuyplace font-weight-bolder ">San Miguel de Tucumán</Card.Footer>
            </Card>
        </motion.div>
    )
}
