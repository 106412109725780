import React, { useState } from 'react';
import { Form, Button, Col } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const BusquedaProducto = ({setProductoBuscado}) => {
    const [inputSearch, setInputSearch] = useState("");
    const onClickSearch = e =>{
      e.preventDefault();
      setProductoBuscado(inputSearch);
    }

    const inputOnChange = e => {
      setInputSearch(e.target.value);
    }

    return(
        <Form className="container">
          <Form.Row className="justify-content-center">
            <Col className="col-sm-12 col-md-8 col-lg-6 mx-0">
              <Form.Control
                className="my-3 border-success"
                placeholder="Busca nuestros productos"
                value = {inputSearch}
                onChange = {inputOnChange}
              />
            </Col>
            <Col className="col-12 col-md-12 col-lg-3 col-xl-2">
              <Button
                className=" zoombottoms my-sm-1 my-md-1 my-lg-3 px-5"
                variant="secondary"
                type="submit"
                onClick = {onClickSearch}
              >
                <FontAwesomeIcon icon={faSearch} className="mr-2" />
                Buscar
              </Button>
            </Col>
          </Form.Row>
      </Form>
    )
}

export default BusquedaProducto;