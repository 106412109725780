import React, { useEffect } from 'react'
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import './ElLaboratorio.css';
import banlab from '../Imagen/banlab.png';
import imgHugo from './../../img/Hugo_Calcagno-360x240.jpg';
import logo from './../../img/logo-mortero.png';

const ElLaboratorio = () => {
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className="back">
            
            <Container className="pb-3 text-center">
                <Row className="d-flex px-0 px-md-3">
                    <Col md={12} lg={4} className="mt-2">
                        <Card className="cardfondo border-0 text-center shadow d-md-flex flex-md-row flex-lg-column">
                            <Image
                                variant="top"
                                thumbnail
                                className=" mx-2 my-2 "
                                src={imgHugo}
                                alt="Second slide"
                            />
                            <Card.Body className="bg-white rounded">
                                <Card.Title>Farm. Hugo Calcagno Guerineau</Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">Dir. Téc. Laboratorio Calcagno – M.P. 1800</Card.Subtitle>
                                <Card.Text>
                                    Miembro de la asociación Española de Farmacéuticos Formulistas.
                                    Miembro de la Sociedad Argentina de Farmacia y Bioquímica Industrial.
                            </Card.Text>
                            </Card.Body>

                        </Card>
                    </Col>
                    <Col

                        className="mt-2 col-xs-12 col-sm-12 col-md-12 col-lg-8">
                        <Card
                            className="h-100 d-flex align-content-center shadow">
                            <div className=" text-center py-2 wheretobuyplace">
                                <Card.Title >El Laboratorio</Card.Title>
                            </div>
                            <Card.Body>
                                <Card.Text className="pt-0 pt-md-4">
                                    En Agosto de 1976, mi padre, el farmacéutico Abel Hugo Calcagno, funda en la ciudad de San Miguel de Tucumán lo que es hoy la Farmacia Calcagno, lugar en el cual de la mano y el consejo sabio de mi padre, comencé a soñar con mi futura profesión.

                                    Mis sueños se encontraban entre la curiosidad y el asombro. Hoy, el orgullo. Durante cuatro años de proficua labor, fue Director Técnico de la vieja y nostálgica Farmacia Massini (fundada en 1864) frente a Plaza Independencia, la plaza más importante de nuestra ciudad.

                                    Sin lugar a dudas, sus comentarios y anécdotas encendieron en mí el profundo amor que siento hoy por mi profesión. Nunca dudé un instante, quería hacer lo que mi padre había experimentado: estar en íntimo contacto con los medicamentos.

                                    Con el correr de los años y habiendo vivido con él experiencias inolvidables, en un pequeño laboratorio de su Farmacia, agradezco a Dios, a mi familia y a la vida, haberme permitido alcanzar mis sueños, sueños que sólo se apoyaron sobre las bases del estudio, la honestidad y el profundo respeto que siento y sentiré por el medicamento.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Container className="mt-2 pb-2">
                    <Row>
                        <Col className="mt-2 px-0 px-md-3">
                            <Card
                                className="shadow">
                                <div className=" text-center py-2 wheretobuyplace">
                                    <Card.Title >Declaración de la Política de Calidad</Card.Title>
                                </div>
                                <Card.Body>
                                    <Card.Text className="text-center">
                                        Es propósito de esta declaración pública de política de calidad del Laboratorio Calcagno, difundir el compromiso de nuestra organización de brindar una atención profesional y personalizada en nuestros servicios de elaboración y venta de medicamentos Herbarios, cumpliendo con:
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Container className="mb-2 p-0 px-md-3">
                            <Row className=" mt-2 mb-2">
                                <Col sm={12} md={12} lg={6}>
                                    <Card>
                                        <div className="text-center py-2 wheretobuyplace">
                                            <Card.Title>Nuestros Clientes </Card.Title>
                                        </div>
                                    </Card>
                                    <Card>
                                        <Card.Body className="d-flex align-items-center p-0">
                                            <Container>
                                                <Row className="">
                                                    <Col sm={1} md={2} lg={2} className="img-item  mx-0 mx-lg-0 py-0 py-md-4">

                                                        <img
                                                            src={logo}
                                                            className=""
                                                            width="50px"
                                                            alt="Laboratorios Calcagno"
                                                        />

                                                    </Col>
                                                    <Col sm={8} md={10} lg={10}>
                                                        <p className="ml-2 pt-0 pt-md-2 ">Satisfacer los requerimientos de los clientes actuales y futuros, brindando y garantizando atención personalizada.</p>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </Card.Body>
                                    </Card>
                                    <Card>
                                        <Card.Body className="d-flex align-items-center p-0">
                                            <Container>
                                                <Row className="">
                                                    <Col sm={1} md={2} lg={2} className="img-item  mx-0 mx-lg-0 py-0 py-md-3">

                                                        <img
                                                            src={logo}
                                                            className=""
                                                            width="50px"
                                                            alt="Laboratorios Calcagno"
                                                        />

                                                    </Col>
                                                    <Col sm={8} md={10} lg={10}>
                                                        <p className="ml-2 pt-0 pt-md-2">Garantizar el estricto control en la realización del procedimiento y servicio solicitado por los clientes.</p>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </Card.Body>
                                    </Card>
                                    <Card>
                                        <Card.Body className="d-flex align-items-center p-0">
                                            <Container>
                                                <Row className="">
                                                    <Col sm={1} md={2} lg={2} className="img-item  mx-0 mx-lg-0 py-0 py-md-3">

                                                        <img
                                                            src={logo}
                                                            className=""
                                                            width="50px"
                                                            alt="Laboratorios Calcagno"
                                                        />

                                                    </Col>
                                                    <Col sm={8} md={10} lg={10}>
                                                        <p className="ml-2 pt-0 pt-md-2">Generar y mantener un equipo de trabajo de máxima eficiencia garantizando la necesaria coordinación entre todas las áreas de trabajo.</p>
                                                    </Col>
                                                </Row>
                                            </Container>

                                        </Card.Body>
                                    </Card>
                                    <Card>
                                        <Card.Body className="d-flex align-items-center p-0">
                                            <Container>
                                                <Row className="">
                                                    <Col sm={1} md={2} lg={2} className="img-item  mx-0 mx-lg-0 py-0 py-md-5">

                                                        <img
                                                            src={logo}
                                                            className=""
                                                            width="50px"
                                                            alt="Laboratorios Calcagno"
                                                        />

                                                    </Col>
                                                    <Col sm={8} md={10} lg={10}>
                                                        <p className="ml-2 pt-0 pt-md-2">Asegurar el cumplimiento del sistema de la gestión de calidad de Laboratorio Calcagno y promover las acciones para la mejora continua de los servicios que brinda la organización.</p>
                                                    </Col>
                                                </Row>
                                            </Container>

                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col sm={12}  md={12} lg={6} >
                                    <Card className="mt-2 mt-lg-0">
                                        <div className="text-center py-2 wheretobuyplace">
                                            <Card.Title >Nuestra Empresa</Card.Title>
                                        </div>
                                    </Card>
                                    <Card>
                                        <Card.Body className="text-center p-0">
                                            <Container>
                                                <Row className="">
                                                    <Col sm={1} md={2} lg={2} className="img-item  mx-0 mx-lg-0 py-0 py-md-5">

                                                        <img
                                                            src={logo}
                                                            className=""
                                                            width="50px"
                                                            alt="Laboratorios Calcagno"
                                                        />

                                                    </Col>
                                                    <Col sm={8} md={10} lg={10}>
                                                        <p className="ml-0 pt-0 pt-md-3">El objetivo de nuestra gestión empresarial, es alcanzar y mantener los máximos niveles de calidad, tanto en el gerenciamiento, como en el desarrollo de trabajo en equipo y la mejora continua de nuestras actividades.</p>

                                                    </Col>
                                                </Row>
                                            </Container>



                                        </Card.Body>
                                    </Card>
                                    <Card>
                                        <Card.Body className="d-flex align-items-center p-0">
                                            <Container>
                                                <Row className="">
                                                    <Col sm={1} md={2} lg={2} className="img-item  mx-0 mx-lg-0 py-0 py-md-3">

                                                        <img
                                                            src={logo}
                                                            className=""
                                                            width="50px"
                                                            alt="Laboratorios Calcagno"
                                                        />

                                                    </Col>
                                                    <Col sm={8} md={10} lg={10}>
                                                        <p className="ml-4 pt-0 pt-md-4">Mantener precios siempre razonables y competitivos.</p>

                                                    </Col>
                                                </Row>
                                            </Container>
                                        </Card.Body>
                                    </Card>
                                    <Card>
                                        <Card.Body className="d-flex align-items-center p-0">
                                            <Container>
                                                <Row >
                                                    <Col sm={1} md={2} lg={2} className="img-item  mx-0 mx-lg-0 py-0 py-md-4">

                                                        <img
                                                            src={logo}
                                                            className=""
                                                            width="50px"
                                                            alt="Laboratorios Calcagno"
                                                        />

                                                    </Col>
                                                    <Col sm={8} md={10} lg={10}>
                                                        <p className="ml-2 pt-0 pt-md-3">Mantener la lealtad de los clientes procurando liderazgo regional en la eficiente realización de medicamentos herbarios.</p>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </Card.Body>
                                    </Card>

                                </Col>
                            </Row>
                        </Container>


                    </Row>
                </Container>



            </Container>
            <Image src={banlab} fluid  alt=""/>
        </div>

    );
}

export default ElLaboratorio;