import React, { useEffect } from 'react';
import { Container, Image } from 'react-bootstrap';
import banlab from '../Imagen/banlab.png';
import RedesSociales from './RedesSociales';
import FormContact from './FormContact';

function Contact(){

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className="back">
            <Container className="pt-4"> 
                <FormContact />
                <RedesSociales />
            </Container>
            <Image src={banlab} fluid  alt=""/>
        </div>
    );
}

export default Contact;