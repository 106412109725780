const medicamentos = [
    {
        "nombre":"Garcinia Gel Liporeductor Frio",
        "descripcion":"Elaborado con la especie vegetal llamada Garcinia cambogia, que contiene el ácido de fruta que quema las grasas. Este fitoterápico tópico con efecto frío por el agregado de alcanfor y mentol reduce las adiposidades localizadas con una intensa acción lipolítica.",
        "curiosidad":"Garcinia cambogia es una especie vegetal originaria de Africa. El ácido hidroxicítrico es el máximo exponente de su rica composición fitoquímica. Actúa a nivel celular del Ciclo de Krebs.",
        "accionTerapeutica":"Liporeductor criógeno y antioxidante",
        "posologia":"1-2 aplicaciones diarias, de manera circular en la zona que desea reducir, hasta la total absorción del gel.",
        "autorizacion":"Según Resolución D.F.F. N° 330/10",
        "siprosa":true,
        "urlImg":"https://i.ibb.co/Bqc246k/garcinia.jpg",
        "urlEnvase": "https://i.ibb.co/V2DZ0VJ/Garcinia-1.jpg",
        "presentacion":"Envase de gel. Contenido neto: 250grs.",
        "categoria":"Adelgazantes",
        "contraindicaciones":"",
        "composicion":""
    },
    {
        "nombre":"Cetona de Frambuesa",
        "descripcion":"Las Cetonas de Frambuesa estimulan el metabolismo celular, la termogénesis y la lipólisis, fundamentales en la combustión de las grasas acumuladas y su transformación en energía. Estimulan la producción de una hormona llamada Adiponectina que participa en el metabolismo de la glucosa y en la combustión de las grasas. En personas con sobrepeso y diabetes mellitus tipo II, los niveles de ésta hormona son bajos, y la grasa se acumula en la zona abdominal. Además disminuye la acción de la enzima Lipasa Pancreática, lo que provoca menor absorción de grasas.",
        "curiosidad":"Es muy beneficiosa la administración conjunta con Flores de Bach en pacientes que presenten trastornos de ansiedad. Deberá consultarse.",
        "posologia":"1 cápsula antes del almuerzo y de la cena.",
        "contraindicaciones":"Hipersensibilidad a la frambuesa.",
        "composicion":"Cada cápsula contiene Extracto Seco de Frambuesa 400 Mg.",
        "autorizacion":"Según resolución N°309/DFF y TM/2015",
        "siprosa":false,
        "urlImg":"https://i.ibb.co/pdDD7n5/Calcagno-Destacadas-Frambuesa.jpg",
        "urlEnvase": "https://i.ibb.co/0yyY3V2/frambuesa-2.jpg",
        "presentacion":"Cada envase contiene 30 cápsulas.",
        "categoria":"Adelgazantes"   
    },
    {
        "nombre":"Mango Africano",
        "descripcion":"Indicado para el tratamiento del sobrepeso y la obesidad. Disminuye la circunferencia abdominal y la grasa corporal de modo significativo. Disminuye el colesterol y los triglicéridos. Coadyuvante de una dieta hipocalórica y actividad física dosificada.",
        "curiosidad":"El Mango africano (Irvingia gabonensis) es originario de Gabón (Africa). El fruto del mango que encontramos en nuestras verdulerías o supermercados es Mangífera índica. Este último sólo presenta propiedades nutricionales.",
        "posologia":"1 cápsula en ayunas y 1 cápsula después de la cena.",
        "contraindicaciones":"Ninguna.",
        "composicion":"Cada cápsula contiene 300 mg. de Extracto Seco Estandarizado de Mango Africano.",
        "autorizacion":"Según Resolución J.D.F.",
        "siprosa":true,
        "urlImg":"https://i.ibb.co/QM2S6vS/mango-africano-nombre-02.jpg",
        "urlEnvase": "https://i.ibb.co/Xp1PNHy/mango-africano.jpg",
        "presentacion":"Cada envase contiene 30 cápsulas.",
        "categoria":"Adelgazantes"   
    },
    {
        "nombre":"Café Verde",
        "descripcion":"Indicado para el tratamiento del sobrepeso y la obesidad. Reduce la absorción de glucosa (azúcar). Este efecto en el metabolismo del azúcar induce el uso de ácidos grasos como fuente de energía en el organismo. Coadyuvante de una dieta hipocalórica y actividad física dosificada.",
        "curiosidad":"El Café Verde es el café sin tostar. Es la mayor fuente vegetal de compuestos fitoquímicos denominados ácidos clorogénicos, responsables de la actividad farmacológica propuesta.",
        "accionTerapeutica":"Complemento dietario.",
        "posologia":"1 cápsula antes del almuerzo y de la cena.",
        "contraindicaciones":"Hipersensibilidad a los componentes de la fórmula.",
        "Observaciones":"Conservar en ambiente fresco y seco.",
        "autorizacion":"Según Resolución J.D.F.",
        "siprosa":true,
        "urlImg":"https://i.ibb.co/xLjPNnw/cafeverde-360x205.jpg",
        "urlEnvase": "https://i.ibb.co/3Y7bGfG/cafe-verde1.jpg",
        "presentacion":"Cada envase contiene 30 cápsulas de gelatina dura de Extracto seco de Café verde 400 Mg.",
        "categoria":"Adelgazantes",
        "composicion":""
    },
    {
        "nombre":"Mango Africano Plus",
        "descripcion":"Es un fitoaminocomplejo indicado para el tratamiento del sobrepeso y la obesidad. El agregado del aminoácido L-carnitina, a la formulación; potencia aún más el efecto lipolítico ultraselectivo. Aporta grandes beneficios en presencia de actividad física aeróbica.",
        "curiosidad":"El Mango africano Plus está destinado a personas que deseen bajar de peso, pero que tengan una actividad física con estricta frecuencia.",
        "posologia":"1 cápsula en ayunas, y 1 cápsula después de la cena.",
        "contraindicaciones":"Ninguna.",
        "composicion":"Cada capsula contiene extracto seco estandarizado de Mango Africano 300 mg.",
        "autorizacion":"Según Resolución N° 334/13 DFF y TM",
        "siprosa":true,
        "urlImg":"https://i.ibb.co/fG1K8Gb/mafricano-21.jpg",
        "urlEnvase": "https://i.ibb.co/KspV5Cf/mango-africano-plus.jpg",
        "presentacion":"30 cápsulas.",
        "categoria":"Adelgazantes"   
    },
    {
        "nombre":"Acaí Berry",
        "descripcion":"Euterpe oleracea (acaí berry), la droga vegetal está representada por sus frutos de intenso color violeta oscuro. Es originario de Brasil. Es un potente fitofármaco antioxidante. Sus componentes fitoquímicos, lo hacen un excelente aliado para el tratamiento del sobrepeso y/u obesidad. Disminuye el estrés oxidativo. Presenta propiedades inmunoestimulantes y neuroprotectoras. Mejora la visión. Disminuye el colesterol y los triglicéridos en la sangre. Estudios científicos comprobaron efectos antialérgicos significativos.",
        "posologia":"1 cápsula después del desayuno y de la merienda, durante un tiempo no menor a 6 (seis) meses.",
        "contraindicaciones":"Hipersensibilidad al fruto de acaí berry.",
        "contenido":"Cada cápsula contiene extracto seco de acaí berry (fruto) 1:1 500 mg.",
        "urlImg":"https://i.ibb.co/wr5YmbW/acai-berry-destacada.jpg",
        "urlEnvase": "",
        "categoria":"Adelgazantes",
        "composicion":""   
    },
    {
        "nombre":"Matricaria",
        "descripcion":"Según la Agencia Europea del Medicamento (EMA) y ESCOP, Matricaria está indicada en la prevención y tratamiento de la migraña. Varios estudios clínicos ponen de manifiesto que la hoja de Matricaria es capaz de reducir la frecuencia de los ataques migrañosos, así como los vómitos asociados a los ataques, la severidad de los ataques y la necesidad de analgésicos.",
        "curiosidad":"Es un buen complemento para los estados migrañosos, la administración concomitante de Magnesio, Coenzima Q10 y Flores de Bach. Deberá consultarse.",
        "posologia":"1 (una) cápsula cada 8 horas durante tres meses consecutivos. Dosis de mantenimiento: 1 cápsula por día, después del desayuno.",
        "contraindicaciones":"Hipersensibilidad a los componentes de la formula.",
        "composicion":"Cada cápsula contiene Extracto seco Matricaria 400 mg",
        "autorizacion":"Según Resolución N°217/DFF Y TM/2015",
        "siprosa":true,
        "urlImg":"https://i.ibb.co/XXXkjcp/matricaria.jpg",
        "urlEnvase": "https://i.ibb.co/YLrjV5z/matricaria1.jpg",
        "presentacion":"30 cápsulas.",
        "categoria":"Antimigrañoso"   
    },
    {
        "nombre":"Resveratrol",
        "descripcion":"Es un potente antioxidante de la vitis vinífera, con propiedades cardioprotectoras, neuroprotectoras y quimiopreventivas. Resveratrol es una molécula antienvejecimiento, ya que estimula los genes responsables de la longevidad. Activa los procesos metabólicos relacionados con la duración de la vida.",
        "curiosidad":"El Resveratrol Antienvejecimiento presenta un diseño fitofarmacológico de naturaleza preventiva. La dosis máxima diaria de Resveratrol en la Medicina Ortomolecular para el tratamiento de patologías múltiples es de 500 mg.",
        "posologia":"1 cápsula después del desayuno.",
        "contraindicaciones":"Evitar el uso del simultáneo de Resveratrol con antiagregantes plaquetarios y anticoagulantes ya que podría incrementar el riesgo de sangrado.",
        "composicion":"Cada capsula contiene 50 mg de Trans-Resveratrol.",
        "autorizacion":"Según Resolución N° 483/13 DFF y TM",
        "siprosa":true,
        "urlImg":"https://i.ibb.co/2tXFdD6/resveratrol.jpg",
        "urlEnvase": "https://i.ibb.co/fFgfN2J/resveratrol1.jpg",
        "presentacion":"30 cápsulas.",
        "categoria":"Antioxidante"   
    },
    {
        "nombre":"Coenzima Q 10",
        "descripcion":"Aterosclerosis, cáncer, diabetes, enfermedad de parkinson, enfermedades periodontales, infarto, infertilidad masculina, migralas, síndrome de fatiga crónica, astenia psico-física. Enfermedades mitocondriales. Estrés crónico.",
        "posologia":"1-2 cápsulas diarias, según criterio profesional Médico/Farmaceútico. Después del desayuno y/o la merienda.",
        "contraindicaciones":"Hipersensibilidad al componente de la fórmula.",
        "composicion":"Cada cápsula contiene Coenzima Q10 60 Mg.",
        "urlImg":"https://i.ibb.co/hFj3kPQ/coenzima-q10-destacada.jpg",
        "urlEnvase": "",
        "presentacion":"Cada envase contiene 30 cápsulas.",
        "categoria":"Antioxidante"   
    },
    {
        "nombre":"Bayas de Goji",
        "descripcion":"Lycium barbarum (bayas de goji), la droga vegetal está representada por sus frutos de intenso color rojo. Es originario del Tíbet, India. Es un potente fitofármaco antioxidante. Promueve la longevidad y podemos considerarlo un fitofármaco nutrigenómico. Presenta marcado efecto terapéutico inmunoestimulante. Es hipoglucemiante. Disminuye el colesterol y los triglicéridos en la sangre. Estudios científicos confirman su efecto neuroprotector. El color rojo, de las bayas; indican su gran contenido en moléculas antioxidantes y carotenoides, responsables de su efecto optimizador de la visión. Previene el cáncer.",
        "posologia":"1 cápsula después del desayuno, durante un tiempo no menor a 6 (seis) meses.",
        "contraindicaciones":"Hipersensibilidad a las bayas de goji.",
        "contenido":"Cada cápsula contiene extracto seco de bayas de goji (fruto) 1:1 500 mg",
        "urlImg":"https://i.ibb.co/qdRrqM6/bayas-goji-destacada.jpg",
        "urlEnvase": "",
        "categoria":"Antioxidante",
        "composicion":""   
    },
    {
        "nombre":"Aceite de Pippermint",
        "descripcion":"Es un antiespasmódico natural. Específicamente indicado para el síndrome del intestino irritable.",
        "curiosidad":"Es de suma importancia el abordaje de un tratamiento conjunto con la Terapia Floral, de las Flores de Bach. Medicamento vibracional que actúa a nivel de las cuestiones emocionales y psicoafectivas del ser humano.",
        "accionTerapeutica":"Antiespasmódico natural",
        "posologia":"1 cápsula después del almuerzo y de la cena.",
        "contraindicaciones":"Hipersensibilidad al aceite de Pippermint.",
        "contenido":"Cada cápsula contiene 152 Mg. de Aceite de Pippermint.",
        "autorizacion":"Según Resolución J.D.F.",
        "siprosa":true,
        "urlImg":"https://i.ibb.co/S64Zdrf/pippermint1.jpg",
        "urlEnvase": "https://i.ibb.co/HFxkwR2/pippermint.jpg",
        "presentacion":"Envase conteniendo 30 cápsulas.",
        "categoria":"Colon irritable", 
        "composicion":""  
    },
    {
        "nombre":"Gotas digestivas",
        "descripcion":"Elaboradas con Alcachofa, carqueja, boldo y mentol. Tienen propiedades digestivas y hepatoprotectoras. Disminuyen la pesadez estomacal.",
        "curiosidad":"Ésta formulación herbaria es ideal para aquellas personas que le hayan extirpado la vesícula biliar, y tengan dificultad para llevar a cabo una buena digestión.",
        "accionTerapeutica":"Hepatoprotector, colagogo y colerético.",
        "posologia":"20 a 30 gotas en un vaso con agua después de las comidas.",
        "autorizacion":"Según Resolución J.D.F. N°089-09",
        "siprosa":true,
        "urlImg":"https://i.ibb.co/yg8Q1rF/gotasdigestivas.jpg",
        "urlEnvase": "https://i.ibb.co/QYXn33Z/Gotas-Digestivas.jpg",
        "presentacion":"Frasco de 60 cc.",
        "categoria":"Digestivos",
        "contraindicaciones":"",
        "composicion":""      
    },
    {
        "nombre":"Rusco Gel Frío",
        "descripcion":"Indicado para el tratamiento tópico de la mala circulación, piernas cansadas, várices y trastornos linfáticos.",
        "curiosidad":"RUSCO GEL FRIO es de aplicación tópica exclusiva para las piernas. No combate hemorroides ni tampoco fisuras anales.",
        "accionTerapeutica":"Tónico venoso. Antiedematoso. Acción venoprotectora y vasoconstrictora.",
        "posologia":"1-2 aplicaciones diarias, de manera ascendente.",
        "observaciones":"Mayor eficacia terapéutica que el Castaño de Indias.",
        "autorizacion":"Según Resolución J.D.F. N°503-09",
        "siprosa":true,
        "urlImg":"https://i.ibb.co/MDgGmGx/rusco-gel.jpg",
        "urlEnvase": "https://i.ibb.co/k6ThBMY/Rusco.jpg",
        "presentacion":"Envase de gel. Contenido neto: 150grs.",
        "categoria":"Flebotónicos",   
        "contraindicaciones":"",
        "composicion":""  
    },
    {
        "nombre":"Rusco Premium",
        "descripcion":"Indicado para el tratamiento de la mala circulación, várices, flebitis, hemorroides y trastornos linfáticos.",
        "curiosidad":"Si los problemas son linfáticos, por ejemplo edematización de los tobillos, deberá consultarse específicamente sobre MELILOTO Premium.",
        "accionTerapeutica":"Tónico venoso. Antiedematoso. Acción venoprotectora.",
        "posologia":"1 cápsula cada 12-24 horas después del almuerzo y/o cena.",
        "observaciones":"Mayor eficacia terapéutica que el Castaño de Indias.",
        "autorizacion":"Según Resolución J.D.F. N°255-09",
        "siprosa":true,
        "urlImg":"https://i.ibb.co/ySTFw39/rusco-1.jpg",
        "urlEnvase": "https://i.ibb.co/RQ7rP9M/Rusco-Premium.jpg",
        "presentacion":"Envase conteniendo 30 cápsulas. Cada cápsula contiene 320 mg. del extracto seco estandarizado de Ruscus Aculeatus.",
        "categoria":"Flebotónicos",    
        "contraindicaciones":"",
        "composicion":"" 
    },
    {
        "nombre":"Jarabe de Achicoria",
        "descripcion":"Es un tónico digestivo. Estimula todas las funciones digestivas. Laxante natural. Normaliza el funcionamiento intestinal. No produce dolor ni hinchazón.",
        "curiosidad":"Si el Jarabe de ACHICORIA resultara insuficiente, deberá consultarse por ALOE PLUS comprimidos.",
        "accionTerapeutica":"Normaliza el funcionamiento intestinal. No produce dolor ni hinchazón.",
        "posologia":"1 cucharada de postre (10 ml), a la noche, antes de acostarse.",
        "observaciones":"Esta formulación está contraindicada en pacientes diabéticos debido a que se prepara a partir de jarabe simple.",
        "autorizacion":"Según Resolución J.D.F.",
        "siprosa":true,
        "urlImg":"https://i.ibb.co/x7XNbyb/achicoria1.jpg",
        "urlEnvase": "https://i.ibb.co/rddthzw/achicoria.jpg",
        "presentacion":"Frasco de 200 ml.",
        "categoria":"Laxante natural", 
        "contraindicaciones":"",
        "composicion":""       
    },
    {
        "nombre":"Aceite de Coco",
        "descripcion":"Indicado para la prevención y tratamiento de todas las enfermedades neurológicas. El aceite de coco se compone casi el 90 % de grasas saturadas, los beneficiosos ácidos grasos saturados de cadena media de suma utilidad en los déficit cognitivos (falta de memoria) y enfermedades de Parkinson y Alzheimer. Estos AGSCM, son un gran combustible bioquímico para las neuronas.",
        "accionTerapeutica":"Es un buen complemento del ACEITE DE COCO, para fines neurológicos; la administración concomitante de Coenzima Q10. Deberá consultarse.",
        "posologia":"2-4 cápsulas diarias, preferentemente después de las comidas principales.",
        "contraindicaciones":"Ninguna",
        "composicion":"Cada capsula contiene 350 mg de aceite de coco purísimo.",
        "autorizacion":"Según Resolución N° 017/DGFS/2015",
        "siprosa":true,
        "urlImg":"https://i.ibb.co/1f7MvZf/coco.jpg",
        "urlEnvase": "https://i.ibb.co/197sdC1/coco1.jpg",
        "presentacion":"60 cápsulas.",
        "categoria":"Sistema nervioso central",        
    },
    {
        "nombre":"Lúpulo",
        "descripcion":"Disminuye la tensión nerviosa. Estos efectos se han comprobado en ensayos in vivo, en los que ha ejercido un efecto inductor del sueño y una disminución de la actividad espontánea.",
        "curiosidad":"El LÚPULO, podrá ser utilizado en pacientes con síndrome de abstinencia nicotínica.",
        "posologia":"1 cápsula después del desayuno, y de la cena.",
        "contraindicaciones":"Tumores hormono-dependientes e hiperestrogenismo. Los pacientes con síndrome depresivo tampoco deben ingerir lúpulo ya que el efecto sedativo puede incrementar los síntomas.",
        "composicion":"Cada capsula contiene extracto seco de Lúpulo 200 mg.",
        "autorizacion":"Según Resolución N°096/DFF Y TM/2015",
        "siprosa":true,
        "urlImg":"https://i.ibb.co/RTySt2D/lupulo.jpg",
        "urlEnvase": "https://i.ibb.co/DtvyT7S/lupulo1.jpg",
        "presentacion":"30 cápsulas.",
        "categoria":"Sistema nervioso central",        
    },
    {
        "nombre":"Ginkgo Biloba Premium",
        "descripcion":"Es un potente antioxidante que neutraliza los radicales libres que dañan las células nerviosas. Aumenta la cantidad de oxígeno disponible para el cerebro, esto lo logra al aumentar el suministro de sangre al cerebro y mejorar la disponibilidad de glucosa (azúcar) en el mismo. Evita la coagulación de la sangre. También mejora la transmisión de información en las células cerebrales.",
        "curiosidad":"El GINKGO BILOBA Premium deberá tenerse siempre en cuenta, por su gran utilidad, en aquellos pacientes que hayan sufrido un ACV isquémico.",
        "posologia":"1 cápsula después del desayuno, se recomienda para comenzar cualquier tratamiento. Podría administrarse hasta 3 cápsulas diarias, según la patología.",
        "contraindicaciones":"Hipersensibilidad individual a los componentes del Ginkgo biloba; embarazo y lactancia.",
        "composicion":"Cada capsula contiene extracto seco estandarizado de Ginkgo biloba 80 mg.",
        "autorizacion":"Según Resolución N° 016/DGFS/2015",
        "siprosa":true,
        "urlImg":"https://i.ibb.co/fp3gt3D/gingko.jpg",
        "urlEnvase": "https://i.ibb.co/xmPM8rZ/ginkgo-biloba.jpg",
        "presentacion":"30 cápsulas.",
        "categoria":"Sistema nervioso central",        
    },
    {
        "nombre":"Uña de Gato",
        "descripcion":"Uña de Gato (Uncaria tomentosa) es un fitofármaco antiinflamatorio osteoarticular de probada eficacia científica y clínica. Está indicada en el tratamiento coadyuvante de la Artrosis y Artritis Reumatoidea en casos leves y moderados.",
        "curiosidad":"Pacientes alérgicos a fármacos analgésicos y antiinflamatorios tipo Aspirina, si pueden ingerir Uña de Gato.",
        "posologia":"1 cápsula después del almuerzo y la cena.",
        "contraindicaciones":"Se desaconseja se uso durante el embarazo y la lactancia.",
        "composicion":"Cada Cápsula contiene Extracto seco de Uña de Gato 250 Mg",
        "autorizacion":"Según resolución N° 308/DFF y TM/2015",
        "siprosa":false,
        "urlImg":"https://i.ibb.co/zQwsvNz/Calcagno-Destacadas-U-a-de-gato.jpg",
        "urlEnvase": "https://i.ibb.co/CtKxF2z/unia-gato.jpg",
        "presentacion":"El envase contiene 30 cápsulas.",
        "categoria":"Antiartrósicos",        
    },
    {
        "nombre":"Palta & Soja",
        "descripcion":"Los insaponificables contenidos en la tintura madre de la Palta y Soja están indicados para el tratamiento de la artrosis y la artritis reumatoidea. Promueven una regeneración del cartílago articular, con un marcado efecto antiinflamatorio natural.",
        "curiosidad":"La tintura madre de Palta & Soja está indicada para el tratamiento herbario de la artrosis y artritis reumatoidea leve y moderada. Son un complemento ideal la Uña de gato Premium cápsulas y la Capsaicina gel alcanforada.",
        "accionTerapeutica":"Efectivo tratamiento de la artrosis.",
        "posologia":"30 gotitas en un vaso con agua después del almuerzo y cena.",
        "autorizacion":"Según Resolución J.D.F. Nº 472/08",
        "siprosa":true,
        "urlImg":"https://i.ibb.co/Z8qTDQJ/palta-soja.jpg",
        "urlEnvase": "https://i.ibb.co/3WFqRSh/Palta-y-Soja.jpg",
        "presentacion":"Frasco de 60 cc.",
        "categoria":"Antiartrósicos", 
        "contraindicaciones":"",
        "composicion":""       
    },
    {
        "nombre":"Serenoa Repens",
        "descripcion":"Indicado para el tratamiento de la hipertrofia prostática benigna y del adenoma de próstata.",
        "curiosidad":"El tratamiento fitofarmacológico de la glándula prostática casi siempre es prolongado. Son complementos útiles la Granada Premium para disminuir valores no deseados de PSA, y el potente antioxidante prosta-selectivo Licopeno. También el Polen es un excelente coadyuvante como antiinflamatorio prostático y descongestivo pelviano.",
        "accionTerapeutica":"Tratamiento de la hipertrofia prostática benigna y del adenoma de próstata.",
        "posologia":"Se aconseja 1 Cápsula por día después del almuerzo.",
        "contraindicaciones":"Intolerancia al principio activo.",
        "autorizacion":"Según Resolución N° 142 / 07",
        "siprosa":true,
        "urlImg":"https://i.ibb.co/Pj737KV/serenoa1.jpg",
        "urlEnvase": "https://i.ibb.co/7JsDhp7/Serenoa-Repens.jpg",
        "presentacion":"Envase conteniendo 30 cápsulas.",
        "categoria":"Antiprostáticos",
        "composicion":"Cada cápsula contiene 320 mg. del Extracto de  Serenoa repens."        
    },
    {
        "nombre":"Resveratrol 250",
        "descripcion":"Resveratrol 250 es un fitofármaco onco-ortomolecular, dado que presenta 5 veces más la dosis de Resveratrol Antienvejecimiento. Resveratrol 250 está específicamente indicado para el tratamiento y profilaxis de varios tipos de tumores.",
        "curiosidad":"Es un muy buen complemento como inmunoestimulante y antiinflamatorio celular la administración concomitante con Aceite de Hígado de Bacalao Cápsulas. Deberá consultarse.",
        "accionTerapeutica":"Cáncer de mama, próstata, estómago, colon, páncreas, tiroides y esófago.",
        "posologia":"1-2 cápsulas diarias, según criterio y/o conocimiento del profesional Médico y/o Farmacéutico; preferentemente después del desayuno y de la merienda.",
        "contraindicaciones":"Lactancia y Embarazo",
        "composicion":"Cada cápsula contiene Resveratrol 250 Mg",
        "autorizacion":"Según resolución N°310/DFF y TM/2015",
        "siprosa":false,
        "urlImg":"https://i.ibb.co/Pgs2Hc4/Calcagno-Destacadas-Resveratrol.jpg",
        "urlEnvase": "https://i.ibb.co/HntspPH/resveratrol-250.jpg",
        "presentacion":"Cada envase contiene 30 cápsulas.",
        "categoria":"Antitumoral",       
    },
    {
        "nombre":"Graviola",
        "descripcion":"Inmunoestimulante. Antitumoral vegetal elaborado con las hojas de la especie vegetal denominada científicamente Annona muricata.",
        "curiosidad":"No es activa para cualquier proceso neoplásico. Deberá consultarse al Profesional Médico y/o Farmacéutico. Se potencia con la administración concomitante de Aceite de coco bebible y Tratamiento de nutrición ortomolecular.",
        "accionTerapeutica":"Inmunoestimulante antitumoral vegetal.",
        "posologia":"1 (una) cápsula después del almuerzo y cena. Durante 3 (tres) meses consecutivos y luego descansar 1 (un) mes.",
        "observaciones":"Se puede administrar concomitantemente con drogas oncológicas de síntesis, al igual que en pacientes bajo quimioterapia o radioterapia.",
        "composicion":"Cada cápsula contiene 350 mg. de Graviola purísima.",
        "autorizacion":"Según Resolución J.D.F.",
        "siprosa":true,
        "urlImg":"https://i.ibb.co/19xdPsz/graviola1.jpg",
        "urlEnvase": "https://i.ibb.co/R7ZgsyC/gaviola.jpg",
        "presentacion":"Cada envase contiene 30 cápsulas.",
        "categoria":"Antitumoral", 
        "contraindicaciones":""     
    },
    {
        "nombre":"Arándano Antidiabético",
        "descripcion":"Elaboradas las cápsulas con el extracto seco de las hojas del arándano. Indicado para el tratamiento de la diabetes. Disminuye los niveles de glucosa en sangre, es un antioxidante y disminuye las complicaciones propias de la diabetes.",
        "curiosidad":"Es importantísimo consultar sobre el Estrés Oxidativo de la Diabetes y sus complicaciones. El efecto neuroprotector del Acido alfa-lipoico. (Nutrición Ortomolecular)",
        "accionTerapeutica":"Antidiabético. Antioxidante",
        "posologia":"Se aconseja 1-2 cápsulas por día, después del almuerzo y/o cena.",
        "contraindicaciones":"Ninguna, es un medicamento natural, de origen vegetal.",
        "composicion":"Cada cápsula contiene 200 mg. del Extracto Seco Estandarizado de la Hoja de Arándano.",
        "autorizacion":"Según Resolución 331/11 DFF y TM",
        "siprosa":true,
        "urlImg":"https://i.ibb.co/sFS3tRq/arandano-diabetes.jpg",
        "urlEnvase": "https://i.ibb.co/4ZYw2sZ/arandanos-diabetes.jpg",
        "presentacion":"Envase conteniendo 30 cápsulas de gelatina.",
        "categoria":"Diabetes",       
    },
    {
        "nombre":"Maca Premium",
        "descripcion":"Es un fitocomplejo multivitamínico, adaptogénico y energizante. Estimulante sexual. Aporta energía, mejora el rendimiento  psicofísico y los procesos de memoria y concentración. Coadyuvante en los estados depresivos y los síntomas de la menopausia.",
        "curiosidad":"Es la variedad rubia del Lepidium peruvianum.Corresponde al 50 % de la cosecha. El paciente que pretenda tomar MACA por primera vez, siempre deberá comenzar con MACA Premium.",
        "posologia":"1-2 comprimidos diarios por un tiempo no menor a 3 (tres) meses.",
        "contraindicaciones":"Ninguna. Es un producto natural de origen vegetal.",
        "autorizacion":"Según Resolución J.D.F. 3797/06",
        "siprosa":true,
        "urlImg":"https://i.ibb.co/PGvcHSk/maca-premium.jpg",
        "urlEnvase": "https://i.ibb.co/J3ZLrXK/MG-47271.jpg",
        "presentacion":"Envase conteniendo 30 comprimidos. Cada comprimido contiene: 500 mg. del polvo micropulverizado.",
        "categoria":"Energizantes",
        "composicion":""

    },
    {
        "nombre":"Maca, Guaraná y Coenzima Q10",
        "descripcion":"Presenta todas las propiedades de la MACA PREMIUM, más las acciones del guaraná y la Coenzima Q10. Formulación energizante de calidad superior. Antioxidante. Cardioprotectora.",
        "curiosidad":"La Coenzima Q10 es un potente antioxidante natural. Esta formulación permite doblemente aumentar el nivel de energía y disminuir el estrés oxidativo celular responsable del cansancio, la fatiga crónica y la activación de múltiples enfermedades.",
        "accionTerapeutica":"Reconstituyente y energizante psicofísico. Vigorizante. Antioxidante.",
        "posologia":"1 cápsula después del desayuno.",
        "contraindicaciones":"Hipersensibilidad a alguno de los componentes de la fórmula.",
        "composicion":"Cada cápsula contiene 500 mg. de Maca en polvo, 250 mg. de extracto seco de Guaraná y 35 mg. de Coenzima Q10.",
        "autorizacion":"Según Resolución J.D.F.",
        "siprosa":true,
        "urlImg":"https://i.ibb.co/0mFbMBH/maca-guarana.jpg",
        "urlEnvase": "https://i.ibb.co/KGPM49J/maca-guarana1.jpg",
        "presentacion":"Envase conteniendo con 30 cápsulas.",
        "categoria":"Energizantes",       
    },
    {
        "nombre":"Maca Negra",
        "descripcion":"Presenta las mismas propiedades que la MACA PREMIUM, y las últimas investigaciones científicas le dan especificidad para aumentar la fertilidad masculina y femenina; como así también mejorar las funciones cerebrales.",
        "curiosidad":"Es la variedad negra del Lepidium peruvianum la más selecta y de menor porcentaje de producción en la cosecha. Se comporta eficazmente en tratamientos ortomoleculares junto a Coenzima Q10, Acido alfa-lipoico, Resveratrol, Aceite de Coco Bebible, Maqui Berry, etc.",
        "posologia":"1-2 cápsulas diarias; después del desayuno y de la merienda.",
        "contraindicaciones":"Hipersensibilidad a la maca.",
        "autorizacion":"Según Resolución J.D.F.",
        "siprosa":true,
        "urlImg":"https://i.ibb.co/ZdxcCqc/maca-negra.jpg",
        "urlEnvase": "https://i.ibb.co/ynw8mnG/maca-negra1.jpg",
        "presentacion":"Envase conteniendo 30 cápsulas de Extracto seco de Maca negra 100 Mg; equivalente a 500 Mg de Maca negra polvo.",
        "categoria":"Energizantes",
        "composicion":""    
    },
    {
        "nombre":"Maca Roja",
        "descripcion":"Presenta las mismas propiedades que la MACA PREMIUM, y las últimas investigaciones científicas le dan especificidad para la prevención y tratamiento de la osteoporosis. Sus resultados fueron significativos frente a la Terapia de Reemplazo Hormonal.",
        "curiosidad":"Es muy buena opción para complementar para el tratamiento de la osteoporosis el Aceite de Coco Bebible.",
        "posologia":"1-2 cápsulas diarias, preferentemente después del desayuno y de la merienda.",
        "contraindicaciones":"Ninguna, es un producto natural, de origen vegetal.",
        "composicion":"Cada capsula contiene 500 mg de Maca polvo Var. Roja.",
        "autorizacion":"Según Resolución N° 221/DGFS/2014",
        "siprosa":true,
        "urlImg":"https://i.ibb.co/ChPRQKM/macaroja.jpg",
        "urlEnvase": "https://i.ibb.co/gP1qZTP/maca-roja-1.jpg",
        "presentacion":"30 cápsulas.",
        "categoria":"Energizantes",    
    },
    {
        "nombre":"Maca + L – arginina",
        "descripcion":"Es un fitoaminocomplejo reconstituyente y energizante psicofísico. Vigorizante. El aminoácido L-arginina es un precursor de la síntesis de óxido nítrico, responsable bioquímico para lograr una buena erección. Este medicamento herbario por acción sinérgica de sus componentes promueve una mejor performance en la esfera sexual masculina.",
        "curiosidad":"El deseo sexual olíbido disminuido se tratará con MACA + TIROSINA; la Tirosina es un aminoácido precursor de la síntesis cerebral del neurotransmisor DOPAMINA. Responsable del deseo sexual o la sensación de bienestar o beneplácito.",
        "posologia":"1 cápsula después del desayuno y de la merienda.",
        "contraindicaciones":"Hipersensibilidad a los componentes de la formula.",
        "composicion":"Cada capsula contiene Ext. seco Maca 200 Mg. (equivalente a Maca polvo 600 MG) y L-Arginina monoclorhidrato 600 Mg.",
        "autorizacion":"Según Resolución N° 097/DFF Y TM/2015",
        "siprosa":true,
        "urlImg":"https://i.ibb.co/ryJDnJ3/maca-arginia.jpg",
        "urlEnvase": "https://i.ibb.co/Y7psLwF/Maca-L-Arginia.jpg",
        "presentacion":"30 cápsulas.",
        "categoria":"Energizantes",    
    },
    {
        "nombre":"Jarabe de Maca Premium",
        "descripcion":"Es un fitocomplejo multivitamínico, adaptogénico y energizante. Estimulante sexual. Aporta energía, mejora el rendimiento psicofísico y los procesos de memoria y concentración. Coadyuvante en los estados depresivos y los síntomas de la menopausia.",
        "curiosidad":"La presentación Bebible presenta mayor biodisponibilidad, al estar los fitocompuestos disueltos en la suspensión. Tener la precaución de agitar el envase antes de cada administración.",
        "posologia":"Niños de 2-5 años: una cucharadita de té; 1 vez por día. Niños de 6-14 años: una cucharadita de postre; 1 vez por día. Mayor de 14 años y adultos: una cucharada sopera; 1 vez por día. No se indica en niños menores de 2 años.",
        "contraindicaciones":"No se indica en niños menores de 2 años.",
        "composicion":"Cada 10 Ml de suspensión contiene Extracto seco de Lepidium peruvianum Var. Rubia 33,3 Mg",
        "autorizacion":"Según Resolución N°216/DFF Y TM/2015",
        "siprosa":true,
        "urlImg":"https://i.ibb.co/ZfP0vQ8/maca.jpg",
        "urlEnvase": "https://i.ibb.co/GFMZXQG/Sin-t-tulo-2.jpg",
        "presentacion":"250 ml.",
        "categoria":"Energizantes",    
    },
    {
        "nombre":"Silimarina",
        "descripcion":"Indicada en todos los trastornos hepáticos. Hepatitis agudas, crónicas y tóxicas. Hígado graso. Cirrosis. Cálculos vesiculares.",
        "curiosidad":"SILIMARINA también presenta resultados significativos en pacientes con psoriasis y aquellos con un estado alérgico crónico por ser estabilizante de la membrana mastocitaria. Deberá consultarse al Profesional.",
        "posologia":"1 cápsula después del almuerzo, durante 5 a 6 semanas de tratamiento.",
        "contraindicaciones":"Hipersensibilidad a la silimarina",
        "observaciones":"No tiene efectos embriotóxicos, puede ser administrado durante el embarazo y el período de lactancia; situación que deberá ser evaluada por el médico especialista.",
        "autorizacion":"Según Resolución J.D.F. Nº 472/08",
        "siprosa":true,
        "urlImg":"https://i.ibb.co/YyyXSdV/silimarina.jpg",
        "urlEnvase": "https://i.ibb.co/wwyRZbz/silimarina1.jpg",
        "presentacion":"Envase conteniendo 30 cápsulas",
        "categoria":"Hepatoprotector",
        "composicion":""    
    },
    {
        "nombre":"Sacha Inchi",
        "descripcion":"Es el aceite de maní inca. Fuente natural riquísima en ácidos grasos esenciales omega 3 de fuente vegetal. Mejora la artritis reumatoidea, el funcionamiento cardiovascular, alivia los síntomas de la psoriasis y el asma y reduce la presión arterial. Disminuye colesterol y triglicéridos.",
        "curiosidad":"El aceite de SACHA INCHI, ha sido considerado el mejor aceite vegetal del mundo rico en ácidos grasos esenciales Omega 3: esto debido a su excelente balance de ácidos grasos Omega 3, 6 y 9.",
        "posologia":"1 – 2 cápsulas diarias después del desayuno y/o merienda.",
        "contraindicaciones":"Ninguna, es un producto natural, de origen vegetal.",
        "observaciones":"Las cápsulas de Sacha Inchi tienen mejor digestibilidad que las de aceite de pescado.",
        "autorizacion":"Según Resolución D.F.F. Nº 369/09",
        "siprosa":true,
        "urlImg":"https://i.ibb.co/KNVwD6y/sancha-inchi-360x205.jpg",
        "urlEnvase": "https://i.ibb.co/Ks9RCLD/Sacha-Inchi.jpg",
        "presentacion":"Envase conteniendo 30 cápsulas.",
        "categoria":"Omega 3",
        "composicion":""    
    },
    {
        "nombre":"Chia",
        "descripcion":"Fuente natural riquísima en ácidos grasos esenciales omega 3 de fuente vegetal. Disminuye colesterol y triglicéridos.",
        "curiosidad":"Las cápsulas de CHIA están manufacturadas con el aceite prensado en frío de la Salvia hispánica, no con las semillas de la chía. Las mismas presentan sólo una acción laxante mecánica por su riqueza en mucílagos que se hinchan en agua.",
        "posologia":"1 cápsula después del almuerzo y la cena. En determinadas patologías puede administrarse, hasta 3 cápsulas diarias.",
        "contraindicaciones":"Ninguna, es un producto natural, de origen vegetal.",
        "observaciones":"Las cápsulas de CHIA tienen mejor digestibilidad que las de aceite de pescado.",
        "composicion":"Cada cápsula contiene 370 mg. de aceite purísimo de chía. (Salvia hispánica).",
        "autorizacion":"Según Resolución J.D.F. 407/12",
        "siprosa":true,
        "urlImg":"https://i.ibb.co/89NDLWt/chia1.jpg",
        "urlEnvase": "https://i.ibb.co/PxPskzR/chia2.jpg",
        "presentacion":"Envase conteniendo 30 cápsulas.",
        "categoria":"Omega 3",    
    },
    {
        "nombre":"Aceite de Higado de Bacalao",
        "descripcion":"El Aceite de Hígado de Bacalao es una fuente natural de ácidos grasos esenciales Omega 3 y vitaminas A y D. Está indicado en hiperlipidemias, deficiencias nutricionales, osteoporosis, trastornos de la visión, artritis reumatoidea, cáncer, etc.",
        "curiosidad":"Dependiendo de la gravedad del estado de salud del paciente, se podrá administrar hasta 4 (cuatro) cápsulas diarias.",
        "posologia":"1 cápsula después del almuerzo y de la merienda.",
        "contraindicaciones":"Hipersensibilidad al Aceite de Higado de Bacalao",
        "composicion":"Cada Cápsula Contiene Aceite de Higado de Bacalao Purisimo 500 Mg",
        "autorizacion":"Según resolución N°306/ DFF y TM/2015",
        "siprosa":false,
        "urlImg":"https://i.ibb.co/wMyVPsx/Calcagno-Destacadas-Bacalao.jpg",
        "urlEnvase": "https://i.ibb.co/C0dLq0J/aceite-bacalao.jpg",
        "presentacion":"Envase conteniendo 30 cápsulas.",
        "categoria":"Omega 3"    
    },
    {
        "nombre":"Arándanos: tintura madre",
        "descripcion":"Es una solución extractiva hidroalcohólica elaborada con los frutos de Vaccinium myrtillus. Está indicada en infecciones urinarias recidivantes. Mejora la visión, combate el colesterol y elimina cálculos renales.",
        "curiosidad":"Es un excelente complemento para mejorar la “visión nocturna” y combatir la degeneración macular, la administración conjunta con Luteína. Deberá consultarse.",
        "posologia":"30 gotitas en un vaso con agua después del almuerzo y cena.",
        "autorizacion":"Según Resolución J.D.F. Nº 227 / 08",
        "siprosa":false,
        "urlImg":"https://i.ibb.co/NjrLF8w/arandanos1.jpg",
        "urlEnvase": "https://i.ibb.co/CmJpmL6/Arandanos.jpg",
        "presentacion":"Frasco de 60 cc.",
        "categoria":"Vías urinarias",  
        "contraindicaciones":"",
        "composicion":""
    },
    {
        "nombre":"Rompe Piedras Premium",
        "descripcion":"Rompe Piedras Premium (Phyllanthus nirurí) es un fitofármaco antilitiásico, diurético y antimicrobiano de probada eficacia científica y clínica. Está indicado en el tratamiento de la prevención y eliminación de cálculos renales. (Micro y macro-cristales de oxalato de calcio)",
        "curiosidad":"Se potencia el tratamiento con la administración conjunta con Tintura Madre de Arándanos. (Ver Vías urinarias)",
        "posologia":"1 cápsula _después_ del almuerzo y de la cena, durante 45 días consecutivos para la eliminación de cálculos renales. Dosis preventiva: 1 cápsula diaria.",
        "contraindicaciones":"Debido a la constatación de pasaje por la barrera hemato-encefálica y leche materna de algunos principios activos, no se aconseja el empleo oral de phyllanthus nirurí durante el embarazo y la lactancia.",
        "composicion":"Cada cápsula contiene Extracto Seco de Phyllanthus niruri 250 Mg.",
        "autorizacion":"Según Resolución N° 307/DFF y TM/2015",
        "siprosa":false,
        "urlImg":"https://i.ibb.co/pz1jfF2/Calcagno-Destacadas-Rompe-Piedras.jpg",
        "urlEnvase": "https://i.ibb.co/LC9BBnH/rompepiedras-premium.jpg",
        "presentacion":"Cada envase contiene 30 cápsulas.",
        "categoria":"Vías urinarias",  
    }
    


]

export default medicamentos;