import React from "react";
import { useParams } from "react-router-dom";
import Catalogo from "../../catalogoMedicamentos";
import { Container, Col, Row, Accordion, Card, Button, OverlayTrigger, Tooltip, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestionCircle,
  faArrowCircleLeft,
  faArrowAltCircleDown
} from "@fortawesome/free-solid-svg-icons";
import banlab from '../Imagen/banlab.png'; 
import { Link } from "react-router-dom";

import "./DetalleMedicamentos.css";
const DetalleMedicamento = () => {
  const idMedicamento = useParams().id.replace("%20", " ");
  const medicamentoBuscado = Catalogo.find(
    (medicamento) => medicamento.nombre === idMedicamento
  );
  return (
    <div>
    <Container className="p-0 my-4 border border-success rounded bg-light alturita">
      <div className="p-2 border-bottom border-success mb-3 background-gray">
        <Row>
          <Col xs={1}>
          <OverlayTrigger
            overlay={
              <Tooltip id={`tooltip-top`}>
                Volver a medicamentos
              </Tooltip>
            }
          >
            <Link to="/medicamentos">
              <FontAwesomeIcon
                icon={faArrowCircleLeft}
                className="mr-2 text-success fa-lg"
              />
            </Link>
          </OverlayTrigger>
          </Col>
          <Col xs={10}>
            <h1 className="text-center">{medicamentoBuscado.nombre}</h1>
          </Col>
        </Row>
        <h6 className="text-center text-success">
          {medicamentoBuscado.accionTerapeutica}
        </h6>
      </div>
      <Row className="p-3">
        <Col xs={12} lg={6} className="d-flex justify-content-end">
          <img
            src={medicamentoBuscado.urlImg}
            alt={medicamentoBuscado.nombre}
            width="100%"
            className="rounded"
          />
        </Col>
        <Col xs={12} lg={6}>
          <p className="text-left mt-3">{medicamentoBuscado.descripcion}</p>
          {medicamentoBuscado.contraindicaciones !== "" ? (
            <p className="text-left text-warning">
              <b>Contraindicaciones: </b>
              {medicamentoBuscado.contraindicaciones}
            </p>
          ) : (
            ""
          )}
          {medicamentoBuscado.composicion !== "" ? (
            <p className="text-left text-success">
              <b>Composición: </b>
              {medicamentoBuscado.composicion}
            </p>
          ) : (
            ""
          )}

          {medicamentoBuscado.autorizacion ? (
            medicamentoBuscado.siprosa ? (
              <div className="border-top border-success mt-5">
                <p className="font-weight-bold mt-2">Fitoterápico Autorizado</p>
                <p>{medicamentoBuscado.autorizacion}</p>
                <span>
                  {" "}
                  Depto. de Fiscalización Farmacéutica y Tecnología Médica.
                </span>
              </div>
            ) : (
              <div>
                <p className="font-weight-bold mt-2">Fitoterápico Autorizado</p>
                <p>{medicamentoBuscado.autorizacion}</p>
              </div>
            )
          ) : (
            ""
          )}
        </Col>
      </Row>

      <Row className="p-3">
        <Col xs={12} lg={6}>
          {medicamentoBuscado.curiosidad !== "" &&
          medicamentoBuscado.curiosidad !== undefined ? (
            <p className="border border-success rounded p-3 mt-3">
              <FontAwesomeIcon
                icon={faQuestionCircle}
                className="mr-2 text-info"
              />
              <b>Curiosidad: </b>
              {medicamentoBuscado.curiosidad}
            </p>
          ) : (
            ""
          )}
        </Col>

        {medicamentoBuscado.urlEnvase !== "" ? (
          <Col xs={12} lg={6}>
            <Accordion defaultActiveKey="0">
              <Card className="mt-4 border border-success">
                <Card.Header className="background-gray">
                  <Accordion.Toggle
                    className="text-success font-weight-bold"
                    as={Button}
                    variant="link"
                  >
                    <FontAwesomeIcon
                icon={faArrowAltCircleDown}
                className="mr-2 text-success"
              />
                    Posología
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse>
                  <Card.Body>
                    <Row>
                      <Col className="border-right border-success border-phone d-flex align-items-center text-center">
                        <p>{medicamentoBuscado.posologia}</p>
                      </Col>
                      <Col className="d-flex flex-column align-items-center">
                        <img
                          src={medicamentoBuscado.urlEnvase}
                          alt={medicamentoBuscado.presentacion}
                          width="150px"
                          className="rounded"
                        />
                        <p className="text-center d-block">
                          {medicamentoBuscado.presentacion}
                        </p>
                      </Col>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        ) : (
          ""
        )}
      </Row>
    </Container>
    <Image src={banlab} fluid  alt=""/>
    </div>
  );
};

export default DetalleMedicamento;
