import React from 'react';
import './Cards.css';
import whatsappImg from './../../img/whatsapp.png';
/* import banenvios from '../Imagen/banenvios.png'; */
import hugocalcagno from '../Imagen/hugocalcagno.png';
import { Row, Card, Col, Image, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';

function Cards() {

    /* const phone = "5493816790209";
    const msg = "Escriba%20su%20mensaje..."; */
    /* const whatsAppLink = `https://api.whatsapp.com/send?phone=${phone}&text=${msg}`; */
    const phoneVeterinaria = "5493815189057";
    const msgVeterinaria = "Escriba%20su%20mensaje...";
    const whatsAppLinkVeterinaria = `https://api.whatsapp.com/send?phone=${phoneVeterinaria}&text=${msgVeterinaria}`;


    return (
        <div className="mt-4 mx-3 mx-md-5 px-3 px-md-5  pb-4">
            <Container fluid className="mx-auto px-2 px-md-5 py-0 py-md-5">
                <Row>
                    <Col className="col-12 col-md-6 col-lg-4 mt-0 mt-md-0">
                        <Card className="cardfondo border-0 text-center ">
                            <Link to='/laboratorio' className='mx-3 my-2'>
                                <Image
                                    variant="top"
                                    thumbnail
                                    className="d-block zoom"
                                    src="https://i.ibb.co/pdMqk45/laboratorio.jpg"
                                    alt="First slide"
                                />
                            </Link>
                            <Card.Body className="bg-white rounded">
                                <Card.Title>El Laboratorio <Link to='/laboratorio'><FontAwesomeIcon icon={faArrowCircleRight} className="ml-2 text-success" /></Link></Card.Title>
                                <Card.Text>
                                    Conozca más sobre la historia de Laboratorio Calcagno y la visión de su director, Farm. Hugo Calcagno Guerineau
                            </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className="col-12 col-md-6 col-lg-4 mt-3 mt-md-0">
                        <Card className="cardfondo border-0 text-center">
                            <Link to='/medicamentos' className='mx-3 my-2'>
                                <Image
                                    variant="top"
                                    thumbnail
                                    className="d-block zoom"
                                    src="https://i.ibb.co/rkwyFp6/remedios.jpg"
                                    alt="Second slide"
                                />
                            </Link>
                            <Card.Body className="bg-white rounded">
                                <Card.Title>Medicamentos <Link to='/medicamentos'><FontAwesomeIcon icon={faArrowCircleRight} className="ml-2 text-success" /></Link></Card.Title>
                                <Card.Text className="pb-2">
                                    Conozca nuestro amplio catálogo <br/> de medicamentos herbarios.
                                </Card.Text>
                            </Card.Body>

                        </Card>
                    </Col>
                    <Col className="col-12 col-md- col-lg-4 ml-0 ml-md-0 ml-lg-0 mt-3 mt-md-5 mt-lg-0 ">
                        <Card className="cardfondo border-0 text-center">
                            <a href={whatsAppLinkVeterinaria} target="_blank" rel="noopener noreferrer" className='mx-3 my-2 bg-transparent border-0'>
                                <Image
                                    variant="top"
                                    thumbnail
                                    className="d-block zoom"
                                    src="https://i.ibb.co/4NsQwdy/veterinaria.jpg"
                                    alt="Third slide"
                                />
                            </a>

                            <Card.Body className="bg-white rounded">
                                <Card.Title>División veterinaria</Card.Title>
                                <Card.Text>
                                    Por consultas y/o informes comunicarse con nuestro asesor veterinario, Dr. Franco Calcagno, haciendo click  <a className="bg-transparent border-0 mt-4 " href={whatsAppLinkVeterinaria} target="_blank" rel="noopener noreferrer">
                                        <Image src={whatsappImg} width="30" height="30" />
                                    </a>
                                </Card.Text>

                            </Card.Body>

                        </Card>
                    </Col>
                    {/* <Col className="col-12 col-lg-8 ml-0 ml-md-0 ml-lg-0 mt-3 mt-md-5 mt-lg-3 ">
                        <Card className="cardfondo border-0 text-center">
                            <a className='my-2 mx-auto py-1 px-3 text-center bg-transparent border-0' href={whatsAppLink} target="_blank" rel="noopener noreferrer">
                         
                                <Image
                                    variant="top"
                                    thumbnail
                                    className=" py-3  py-md-3d-block zoom1"
                                    src={banenvios}
                                    alt="four slide"
                                />
                            
                            </a>

                            <Card.Body className="bg-white rounded">
                                <Card.Title className="mt-2">Envíos a domicilio a todo el país</Card.Title>
                                <Card.Text>
                                    Por envios a domicilio comunicarse con nuestro laboratorio,  <br/>haciendo click aquí:  <a className="bg-transparent border-0 mt-5 pb-2 " href={whatsAppLink} target="_blank" rel="noopener noreferrer"> 
                                        <Image src={whatsappImg} width="40" height="40" />
                                    </a>
                                </Card.Text>

                            </Card.Body>

                        </Card>
                    </Col> */}
                    <Col className="col-12 col-md-12 col-lg-4 ml-0 ml-md-0 ml-lg-0 mt-3 mt-md-5 mt-lg-2 pt-2 ">
                        <Card className="cardfondo border-0 text-center">
                            <Link to='/laboratorio' className='mx-3 my-2'>
                                <Image
                                    variant="top"
                                    thumbnail
                                    className="d-block zoom"
                                    src={hugocalcagno}
                                    alt="Hugo Calcagno"
                                />
                            </Link>

                            <Card.Body className="bg-white rounded mt-3 ">
                                <Card.Text>
                                 Fitomedicina / Terapia Metabólica del Cáncer / Flores de Bach / Nutrición Orthomolecular / Medicina del Estrés y Antienvejecimiento / Terapia Cannábica / Medicamentos Huérfanos / Enfermedades Raras / Tratamiento del Dolor / Farmacia Magistral Veterinaria.
                                </Card.Text>

                            </Card.Body>


                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Cards;