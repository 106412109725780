import React from 'react';
import { faHome, faFlask, faEnvelope, faMapMarkedAlt, faMortarPestle, faShoppingCart, faPumpMedical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logolabmobile from '../Imagen/logolabmobile.png';
import { Navbar, Nav, Image} from 'react-bootstrap';
import logolab from '../Imagen/logolab.png';
import { Link } from 'react-router-dom';
import './Header.css';

export default function Header(){
    return (
        <div className="navcolor text-center">
            <Image src={logolab} className="d-none  d-md-block phoneview" fluid />
            <Image src={logolabmobile} className="d-block d-md-none " fluid />
            <h5 className="text-white font-weight-bold mt-4 d-none d-lg-block">Innovación en medicina Herbaria</h5>
            <Navbar expand="lg" className="navcolor" variant="dark">
                <Navbar.Brand className="text-white text-center font-weight-bold font mx-auto d-block d-lg-none">Innovación en medicina Herbaria</Navbar.Brand> 
                <Navbar.Toggle aria-controls="navigation" />
                <Navbar.Collapse className="text-center" id="navigation">
                    <Nav className="mx-auto">
                        <Link className='nav-link text-white mr-2 hoverlink text-decoration-none' to='/'><FontAwesomeIcon icon={faHome} className="mr-2"/>Inicio</Link>
                        <Link className='nav-link text-white mr-2 hoverlink text-decoration-none' to='/laboratorio'><FontAwesomeIcon icon={faFlask} className="mr-2"/>El laboratorio</Link>
                        <Link className='nav-link text-white mr-2 hoverlink text-decoration-none' to='/dondecomprar/yb'><FontAwesomeIcon icon={faMapMarkedAlt} className="mr-2"/>Donde Comprar</Link>
                        <Link className='nav-link text-white mr-2 hoverlink text-decoration-none' to='/medicamentos'><FontAwesomeIcon icon={faMortarPestle} className="mr-2"/>Medicamentos</Link>
                        <Link className='nav-link text-white mr-2 hoverlink text-decoration-none' to='/dermatologia'><FontAwesomeIcon icon={faPumpMedical} className="mr-2"/>División dermatocosmética</Link>
                        <Link className='nav-link text-white mr-2 hoverlink text-decoration-none' to='/contacto'><FontAwesomeIcon icon={faEnvelope} className="mr-2"/>Contacto</Link>
                        <a className='nav-link text-white mr-2 hoverlink text-decoration-none' href="https://laboratoriocalcagno-tiendaonline.com.ar/"><FontAwesomeIcon icon={faShoppingCart} className="mr-2"/>Tienda</a>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    );
}