import React from 'react';
import './Footer.css';

function Footer(){
    return(
        <footer className="fotcolor text-center text-white py-2">
            <label>Laboratorio Calcagno &copy; Todos los derechos reservados</label>
        </footer>
    );
}

export default Footer;