import React from 'react';
import whatsappImg from './../../img/whatsapp.png';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Producto from '../Producto/Producto';


export default function ListadoDermatologia({ productosParaListar }) {
    const whatsAppLink = `https://api.whatsapp.com/send?phone=5493816790209&text=Escriba%20su%20mensaje...`;

    return (
        <Container className='mb-4 p-2'>
            {productosParaListar.length === 0 ?
                <>
                    <h1 className="py-5 text-white">Lo sentimos!</h1>
                    <h3 className="py-4 text-white">No tenemos actualmente productos con ese nombre</h3>
                    <h3 className="text-white py-4">Por cualquier consulta comunicarse con nuestro laboratorio, haciendo click
                        <a className="bg-transparent border-0 " href={whatsAppLink} target="_blank" rel="noopener noreferrer">
                            <Image src={whatsappImg} className="ml-2" width="40" height="40" />
                        </a>
                    </h3>
                    <br />
                    <h5 className="py-5 text-white">Intente buscando en nuestro catálogo</h5>
                </> :
                <Row>
                    {
                        productosParaListar.sort((a, b) => a.nombre.localeCompare(b.nombre)).map((producto, i) => 
                            <Col sm={12} lg={3} md={4} key={i}>
                                <Producto
                                    idProducto={producto.nombre}
                                    producto={producto}
                                />
                            </Col>
                        )
                    }
                </Row>
            }
        </Container>
    );
}