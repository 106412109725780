const categorias = [
    "Todas",
    "Adelgazantes",
    "Antiartrósicos",
    "Antimigrañoso",
    "Antioxidante",
    "Antiprostáticos",
    "Antitumoral",
    "Colon irritable",
    "Diabetes",
    "Digestivos",
    "Energizantes",
    "Flebotónicos",
    "Hepatoprotector",
    "Laxante natural",
    "Omega 3",
    "Sistema nervioso central",
    "Vías urinarias"
  ];

export default categorias;