import React from "react";
import { Container, Col, Row, OverlayTrigger, Tooltip, Image } from "react-bootstrap";
import { faQuestionCircle, faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Catalogo from "../../catalogoDermatologia";
import { useParams } from "react-router-dom";
import banlab from '../Imagen/banlab.png'; 
import { Link } from "react-router-dom";
import "./DetalleProducto.css";

export default function DetalleProducto() {
  const idProducto = useParams().id.replace("%20", " "),
  productoBuscado = Catalogo.find(producto => producto.nombre === idProducto);

  return (
    <>
      <Container className="p-0 my-4 border border-success rounded bg-light">
        <div className="p-2 border-bottom border-success mb-3 background-gray">
          <Row>
            <Col xs={1}>
            <OverlayTrigger
              overlay={
                <Tooltip id={`tooltip-top`}>
                  Volver a dermatologia
                </Tooltip>
              }
            >
              <Link to="/dermatologia">
                <FontAwesomeIcon
                  icon={faArrowCircleLeft}
                  className="mr-2 text-success fa-lg"
                />
              </Link>
            </OverlayTrigger>
            </Col>
            <Col xs={10}>
              <h1 className="text-center">{productoBuscado.nombre}</h1>
            </Col>
          </Row>
          <h6 className="text-center text-success">
            {productoBuscado.accionTerapeutica}
          </h6>
        </div>
        <Row className="p-3">
          <Col xs={12} className="d-flex flex-column align-items-center justify-content-end">
            <div className="text-center">
              <img
                src={productoBuscado.urlImg}
                alt={productoBuscado.nombre}
                width="100%"
                height="100%"
                className="rounded"
              />
            </div>
          </Col>
          <Col xs={12} lg={12}>
            <p className="text-left mt-5">{productoBuscado.descripcion}</p>
            {productoBuscado.contraindicaciones &&
              <p className="text-left text-warning">
                <b>Contraindicaciones: </b>
                {productoBuscado.contraindicaciones}
              </p>
            }
            {productoBuscado.composicion &&
              <p className="text-left text-success">
                <b>Composición: </b>
                {productoBuscado.composicion}
              </p>
            }
            {productoBuscado.autorizacion && (
              productoBuscado.siprosa ?
                <div className="border-top border-success mt-5">
                  <p className="font-weight-bold mt-2">Fitoterápico Autorizado</p>
                  <p>{productoBuscado.autorizacion}</p>
                  <span>
                    Depto. de Fiscalización Farmacéutica y Tecnología Médica.
                  </span>
                </div> : 
                <>
                  <p className="font-weight-bold mt-2">Fitoterápico Autorizado</p>
                  <p>{productoBuscado.autorizacion}</p>
                </>
            )}
          </Col>
        </Row>

        <Row className="p-3">
          <Col xs={12} lg={6}>
            {productoBuscado.curiosidad &&
              <p className="border border-success rounded p-3 mt-3">
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className="mr-2 text-info"
                />
                <b>Curiosidad: </b>
                {productoBuscado.curiosidad}
              </p>
            }
          </Col>
        </Row>
      </Container>
      <Image src={banlab} fluid alt=""/>
    </>
  );
};