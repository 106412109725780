import React from 'react';
import {Card, Image} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Medicamento.css';

const Medicamento = ({idMedicamentoLink, medicamento, setCategoriaSeleccionada}) => {
    const medicamentoLink = `medicamentos/${idMedicamentoLink}`;
    return(
        <Card className="cardfondo border-0 text-center mb-2">
            <Card.Header onClick= {()=> setCategoriaSeleccionada(medicamento.categoria)} className='bg-white border border-success card-header-categoria p-1'>{medicamento.categoria}</Card.Header>
            <Link to={medicamentoLink} className='mx-2 my-1'>
                <Image
                    variant="top"
                    thumbnail
                    className="d-block zoom"
                    src={medicamento.urlImg}
                    alt={medicamento.nombre}
                />
            </Link>
            <Card.Body className="products rounded shadow-lg">
                <Link to={medicamentoLink} className="text-decoration-none text-dark font-weight-bold">
                    <Card.Title className='text-truncate'>{medicamento.nombre}</Card.Title>
                </Link>
            </Card.Body>
        </Card>
    );
}

export default Medicamento;