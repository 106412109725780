import React from 'react';
import SeccionDermatologia from './components/SeccionDermatologia/SeccionDermatologia';
import DetalleMedicamento from './components/DetalleMedicamento/DetalleMedicamento';
import DetalleProducto from './components/DetalleProducto/DetalleProducto';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ElLaboratorio from './components/ElLaboratorio/ElLaboratorio';
import Medicamentos from './components/Medicamentos/Medicamentos';
import WhereToBuy from './components/WhereToBuy/WhereToBuy';
import Contact from './components/Contact/Contact';
import Header from './components/Navbar/Header';
import Footer from './components/Footer/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './components/Home/Home';

export default function App() {
  return (
    <Router>
      <Header />
      <Switch>
        <Route path='/contacto' component={Contact} />
        <Route path='/dondecomprar' component={WhereToBuy} />
        <Route path='/medicamentos/:id' component={DetalleMedicamento} />
        <Route path='/medicamentos' component={Medicamentos} />
        <Route path='/dermatologia/:id' component={DetalleProducto} />
        <Route path='/dermatologia' component={SeccionDermatologia} />
        <Route path='/laboratorio' component={ElLaboratorio} />
        <Route path='/' component={Home}>
        </Route>
      </Switch>
      <Footer />
    </Router>
  );
}