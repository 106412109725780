import React, { useState, useEffect } from "react";
import { Button, ButtonGroup, Image } from "react-bootstrap";
import BusquedaProducto from './../BusquedaProducto/BusquedaProducto';
import BusquedaCategoria from './../BusquedaCategoria/BusquedaCategoria';
import ListadoMedicamentos from './../ListadoMedicamentos/ListadoMedicamentos';
import Catalogo from "../../catalogoMedicamentos";
import banlab from '../Imagen/banlab.png'
import './Medicamentos.css';

const Medicamentos = () => {
  const [busquedaProducto, setBusquedaProducto] = useState("producto");
  const onClickBusqueda = e => {
    setBusquedaProducto(e.target.value);
    if(busquedaProducto === "producto"){
      setCategoriaSeleccionada("Todas")
      setMedicamentosParaListar(Catalogo)
    } else {
      setProductoBuscado("");
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState("Todas");
  const [medicamentosParaListar, setMedicamentosParaListar] = useState([]);
  useEffect(() => {
    if(categoriaSeleccionada === "Todas"){
      setMedicamentosParaListar(Catalogo)
    } else {
      setMedicamentosParaListar(Catalogo.filter(medicamento => medicamento.categoria === categoriaSeleccionada));
    }
  }, [categoriaSeleccionada])

  const [productoBuscado, setProductoBuscado] = useState("");
  useEffect(() => {
    setMedicamentosParaListar(Catalogo.filter(medicamento => medicamento.nombre.toLowerCase().indexOf(productoBuscado.toLowerCase())>-1))
  }, [productoBuscado])

  return (
    <div className="back container-fluid mb-0 text-center p-0 pb-2 alturito">
        <div className='sticky-top m-0 border border-white pt-3 search-bg'>
        <ButtonGroup aria-label="Basic example" className='mb-0'>
            <Button variant='secondary' className="zoombottoms" value="producto" onClick = {onClickBusqueda} >Buscar por producto</Button>
            <Button variant='outline-dark' value="categorias" onClick = {onClickBusqueda} >Buscar por categoría</Button>
        </ButtonGroup>
        {
            busquedaProducto === "producto" ?
            <BusquedaProducto
                setProductoBuscado = {setProductoBuscado}
            /> 
            :
            <BusquedaCategoria
                setCategoriaSeleccionada = {setCategoriaSeleccionada}
                categoriaSeleccionada = {categoriaSeleccionada}
            />
        }
        </div>
        <ListadoMedicamentos
          medicamentosParaListar= {medicamentosParaListar}
          categoriaSeleccionada = {categoriaSeleccionada}
          setCategoriaSeleccionada = {setCategoriaSeleccionada}
          setBusquedaProducto={setBusquedaProducto}
        />
        <Image src={banlab} fluid  alt=""/>
    </div>
  );
};

export default Medicamentos;
