import React, { useState, useEffect } from "react";
import ListadoDermatologia from '../ListadoDermatologia/ListadoDermatologia';
import BusquedaProducto from '../BusquedaProducto/BusquedaProducto';
import Catalogo from "../../catalogoDermatologia";
import banlab from '../Imagen/banlab.png';
import { Image } from "react-bootstrap";
import './SeccionDermatologia.css';

export default function SeccionDermatologia() {
  const [productoBuscado, setProductoBuscado] = useState(""),
  [productosParaListar, setProductosParaListar] = useState([]);

  useEffect(() => setProductosParaListar(Catalogo.filter(medicamento => medicamento.nombre.toLowerCase().indexOf(productoBuscado.toLowerCase())>-1)), [productoBuscado]);

  useEffect(() => window.scrollTo(0, 0), []);
  
  return (
    <div className="back container-fluid mb-0 text-center p-0 pb-2 alturito">
        <div className='sticky-top m-0 border border-white pt-5 search-bg'>
          <BusquedaProducto setProductoBuscado={setProductoBuscado} />
        </div>
        <ListadoDermatologia productosParaListar={productosParaListar} />
        <Image src={banlab} fluid alt=""/>
    </div>
  );
};