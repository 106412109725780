import React from 'react';
import {Card, Image} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Producto.css';

export default function Producto ({ idProducto, producto }) {
    const productoLink = `dermatologia/${idProducto}`;

    return(
        <Card className="cardfondo border-0 text-center mb-3">
            <Link to={productoLink} className='mx-2 my-1'>
                <Image
                    className=" zoom imgderm"
                    src={producto.urlImg}
                    alt={producto.nombre}
                />
            </Link>
            <Card.Body className="products rounded shadow-lg">
                <Link to={productoLink} className="text-decoration-none text-dark font-weight-bold">
                    <Card.Title className='text-truncate'>{producto.nombre}</Card.Title>
                </Link>
            </Card.Body>
        </Card>
    );
}