import React from 'react'
import { Card, Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion'; 

const pageTransition = {
    in: {
        opacity: 1,
        y:0
},
    out: {
        opacity: 0,
        y: "-100%"
}
}

export default function Yb() {
    return (
        <motion.div  initial="out" animate="in" exit="out" variants={pageTransition}>

            <Card className="text-center">
                <Card.Body>
                    <Row>
                        <Col lg={4}>
                            <Card className="border-0">
                                <Card.Header className="border-0 wheretobuyplace font-weight-bolder">
                                    Farmacia Calcagno
                                    </Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                    Lamadrid 1031- Local 2 <Button type="link" href="https://www.google.com.ar/maps/place/Farmacia+Calcagno/@-26.8214405,-65.2881003,17z/data=!3m1!4b1!4m5!3m4!1s0x9422432cb90691e3:0xb6806fb888d7b159!8m2!3d-26.8214405!4d-65.2859116" target="_blank" variant="success" className="ml-2 mb-1 pb-2 border-0 bg-transparent  zoombottomsmap"><FontAwesomeIcon className="fa-lg" icon={faMapMarkedAlt} /></Button>
                                    </Card.Text>

                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4}>
                            <Card className="border-0">
                                <Card.Header className="border-0 wheretobuyplace font-weight-bolder">
                                Farmacia Del Pueblo
                                    </Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        Av Aconquija 1336 <Button type="link" href="
                                        https://www.google.com.ar/maps/place/Farmacias+Del+Pueblo/@-26.814832,-65.2917226,17z/data=!3m1!4b1!4m5!3m4!1s0x942242d59088bcb5:0x30405ae6572eb44e!8m2!3d-26.814832!4d-65.2895339" target="_blank" variant="success" className="ml-2 mb-1 pb-2 border-0 bg-transparent  zoombottomsmap"><FontAwesomeIcon className="fa-lg" icon={faMapMarkedAlt} /></Button>
                                    </Card.Text>

                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4}>
                            <Card className="border-0">
                                <Card.Header className="border-0 wheretobuyplace font-weight-bolder">
                                Farmacia Del Pueblo Los Troncos
                                    </Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                    Av Aconquija 1812 <Button type="link" href="https://www.google.com.ar/maps/place/Farmacia+del+Pueblo/@-26.813861,-65.2946358,18.04z/data=!4m8!1m2!2m1!1sFarmacia+Del+Pueblo+Los+Troncos+yerba+buena!3m4!1s0x942242d70687c4e7:0xcad0ca002bbcabca!8m2!3d-26.8132429!4d-65.2950803" target="_blank" variant="success" className="ml-2 mb-1 pb-2 border-0 bg-transparent zoombottomsmap"><FontAwesomeIcon className="fa-lg" icon={faMapMarkedAlt} /></Button>
                                    </Card.Text>

                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer  className="border-0 wheretobuyplace font-weight-bolder ">Yerba Buena</Card.Footer>
            </Card>
        </motion.div>
    )
}
